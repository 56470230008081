@import "../variables";

.comp-multiselect-dropdown-container {
  width: 100%;
  margin-bottom: $inputMarginBottom;

  &.error {
    .comp-multiselect-dropdown {
      .input-link {
        &:focus {
          .selected-list {
            border-color: $error-red;
          }

        }
      }

      .selected-list {
        //border: 1px solid $error-red;
        border-color: $error-red;

        &.open {
          border-color: $error-red;
        }
      }
    }
  }

  &.disabled {
    .comp-multiselect-dropdown {
      .input-link {
        cursor: default;
        &:focus {
          .selected-list {
            margin: 1px 0;
            border-width: 1px;
            border-color: $disabledBlueGray;
          }

        }
      }

      .selected-list {
        //border: 1px solid $error-red;
        border-color: $disabledBlueGray;

        &.open {
          margin: 1px 0;
          border-width: 1px;
          border-color: $disabledBlueGray;
        }
      }
    }

  }

  .comp-multiselect-dropdown {
    a, a:visited {
      text-decoration: none;
      color: $input-text;
    }

    .input-link {
      &:focus {
        box-shadow: none;
        outline: none;


        .selected-list {
          margin: 0;
          border-width: 2px;
          border-color: $input-border-focus;
        }

      }
    }

    .selected-list {
      width: 100%;
      list-style-type: none;
      min-height: 44px;
      box-sizing: border-box;

      border-radius: 1px;
      position: relative;
      background-color: $input-background;
      border: 1px solid $input-border;
      padding: 6px 32px 7px 12px;
      margin: 1px 0;

      background-image: url('images/dropdown.svg');

      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .65em auto, 100%;

      &.open {
        margin: 0;
        border-width: 2px;
        border-color: $input-border-focus;
      }


      .item {
        font-family: Open Sans, sans-serif;
        float: left;

        > div {
          display: flex;
          justify-content: space-around;
          background-color: $secondary-gray;
          border-radius: 12px;
          padding: 2px 0 2px 8px;

          .closeButton {
            height: 24px;
            width: 24px;
            margin-left: auto;
            margin-right: 0;

            box-sizing: border-box;

            background-image: url('images/close.svg');
            background-repeat: no-repeat, repeat;
            background-position: left 2px top 2px, 0 0;
            background-size: 18px auto, 100%;
          }

        }


      }
    }

    .dropdown-menu {
      &.closed {
        display: none;
      }

      &.open {
        display: block;
        position: absolute;
        z-index: 2;
      }

      margin: 0 0 0 0;
      /*position: absolute;
      top: 10px;
      left: 0;*/
      width: 100%;

      .menu-list {
        width: 100%;
        list-style-type: none;
        padding: 5px 0;
        margin-top: -2px;


        background-color: $input-background;
        border: 1px solid $input-border;

        .toolTip-click {
          display: flex;
          width: 100%;
        }

        .tooltip-icon-container {
          align-self: flex-end;
        }

        .item {
          font-family: Open Sans, sans-serif;
          height: 50px;
          box-sizing: border-box;
          line-height: 38px;
          display: flex;

          &.selected {
            a, a:visited {
              background-color: $focus-background-color;
              color: $focus-font-color;
            }
          }

          a, a:visited {
            width: 100%;
            height: 100%;
            display: block;
            padding-left: 9px;

            &:hover {
              background-color: $lightBlueText;
              color: $focus-font-color;
            }
          }
        }
      }
    }
  }
  .searchOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}
