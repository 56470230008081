@import "variables";


.rightDrawer {
  width: $drawerWidth;
  flex-shrink: 0;
  position: fixed;
  .rightDrawerPaper {
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25);
    margin-right: $rightMargin;
    border-left: none;
    height:auto;
    background-color: $main-background-color;
    margin-top:$appBarHeight;

    .rightDrawer-title {
      width: $drawerWidth;
      background: $primary-gray;
      border: 1px solid $white;
      box-sizing: border-box;
      border-top-right-radius: $drawerTitleBorderRadius;
    }

    .right-drawer-list {
      > div {
        background-color: $secondary-gray;
        border-left: 1px solid $white;
      }

      .rightListItemLink {
        border-color: $white;
        background-color: $secondary-gray;
        padding-left: 20px;
        &:hover {
          background-color: $secondary-gray;
          border-left: 10px solid $primary-gray;
          padding-left: 10px;
        }
        &:active,
        &:focus {
          color: $focus-font-color;
          background-color: $focus-background-color;
          border-left: 0;
        }
      }

    }
  }
}





