@import "../variables";

.comp-checkbox-group {
  .MuiFormControlLabel-root {

    .MuiCheckbox-root {
      color: $input-border;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      .MuiSvgIcon-root {
        width: 30px;
        height: 30px;
      }

      &.Mui-checked {
        color: $input-border-focus;
      }

      &.Mui-disabled {
        .MuiSvgIcon-root {
          color: $disabled-gray;
        }
      }
    }
  }
  &.error {
    .MuiSvgIcon-root {
      color: $error-red;
      &:hover {
        background-color: rgba(245, 0, 87, 0.04);
      }
    }

    .MuiIconButton-colorSecondary:hover {
      background-color: rgba(245, 0, 87, 0.04);
    }
  }

  .MuiFormControlLabel-label {
    color: $black;
    font-family: Open Sans, sans-serif;
    &.Mui-disabled {
      color: $black;
    }
  }

  .checkbox-row {
    display: flex;
  }

}