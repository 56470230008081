@import "../variables";

.CompContentIdentifierTable-wrapper {
  .CompContentIdentifierTable-mainTitle {
    background-color: $blueGray;
    color: $white;
    text-transform: uppercase;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-style: normal;
    font-weight: bold;
  }

  .CompContentIdentifierTable-container {
    .CompContentIdentifierTable-table {
      .MuiTableHead-root {
        .MuiTableRow-head {
          .MuiTableCell-head {
            background-color: $lightGray;
            color: $primary-blue;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          &.Mui-selected,
          &.Mui-selected:hover {
            background-color: $primary-blue;
            .MuiTableCell-body {
              color: $white;
            }
          }

          .MuiTableCell-body {
            &.selected {

            }
          }
        }
      }
      .MuiTableFooter-root {
        .MuiTableRow-footer {
          .CompContentIdentifierTable-paginator {
            flex-shrink: 0;
            margin-left: 8px;
          }
        }

      }
    }
  }
}

.contentIdentifier-container {
  .listComponent {
    min-height: 255px;
  }
}
