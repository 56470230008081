@import "../variables";

.MuiAccordion-root .Mui-disabled {
  background-color: $input-background;
}

.MuiAccordionSummary-root .Mui-disabled {
  opacity: 1;
}

.locationInfoCtr {
  width: 100% !important;
}

.comp-accordion-container {
  background: $input-background;
  box-sizing: border-box;
  box-shadow: none;
  margin-bottom: 8px;

  .comp-accordion-summary {
    height: 48px;
    min-height: 48px;
    border: 2px solid $secondary-blue;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

    .header-text {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      display: flex;
      align-items: center;
      text-transform: uppercase;

      color: $input-label;

      .comp-accordion-error {
        display: none;
      }
    }

    .MuiAccordionSummary-expandIcon {
      &.Mui-expanded {
        color: $focus-font-color;
      }
    }

    &.Mui-expanded {
      background-color: $focus-background-color;
      border-color: $focus-background-color;

      .header-text {
        color: $focus-font-color;
      }
    }

    &.Mui-focused {
      border: 2px solid $input-border-focus;
      background-color: $input-background;

      .header-text {
        color: $input-label;
      }

      .MuiAccordionSummary-expandIcon {
        color: $input-label;
      }
    }

    &.Mui-disabled {
      border: 2px solid $disabledLightBlue;

      .header-text {
        color: $disabledLightBlueText
      }

      .MuiAccordionSummary-expandIcon {
        color: $disabledLightBlueText
      }
    }
  }

  .comp-accordion-details {
    padding: $inputMarginBottom 0 0 0;
    /* >.MuiGrid-container {
      margin-left: -11px;
      margin-right: -11px;
      width: auto;
    } */
    .MuiGrid-item {
      margin-bottom: 0;
      .MuiGrid-container {
        margin: 0 0 0 -11px;
        width: calc(100% + 11px + 11px);
        .MuiGrid-item {
          //padding-left: 0;
          //padding-right: 0;
        }
      }
    }
  }



  &.error .comp-accordion-summary {
    .header-text > .comp-accordion-error {
      display: inline;
      margin-top: 0;
      margin-left: 5px;
      padding: 5px 8px;
      border-radius: 8px;
      background-color: $input-background;

    }
  }
}