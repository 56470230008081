@import "../variables";

$imageWidth: 44px;
$informationWidth: 135px;
$datesWidth: 180px;
$actionsWidth: 100px;
$leftColWidth: 30%;
$rightColWidth: 30%;

$rowHeight: 36px;
$padding: 10px;

.option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MuiPopover-paper {
  white-space: break-spaces;
  top: 370px;
}

.comp-accordion-details {
  width: initial;
}

.CompFunctionCards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .CompFunctionCards-row {
    //height: $rowHeight;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    border: 1px solid $blueGray;
    margin: 3px 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

    .cell-image {
      width: $imageWidth;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 34px;
        width: auto;
        vertical-align: middle;
      }
    }

    .cell-content {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      margin: 0 10px;
      padding: $padding 0;

      cursor: pointer;

      .information {
        width: 40%;
        min-width: $leftColWidth;
        //align-items: center;

        display: flex;
        justify-content: left;
        flex-direction: column;


        .title {
          font-weight: bold;
          padding-right: 10px;
        }

        .desc {
        }
      }

      .organizers {
        flex: 1;
        //min-width: $informationWidth;
        display: flex;
        align-items: center;
      }
            
      .participants {
        justify-content: center;
        flex: 1 1;
        display: flex;
        align-items: center;
      }
    }

    .cell-right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      min-width: calc(#{$datesWidth} + #{$actionsWidth});

      margin: 10px 10px 10px 0;

      .dates {
        width: $datesWidth;
        cursor: pointer;
      }

      @media screen and (max-width: 600px) {
        width: auto;
      }

      .actions {
        width: $actionsWidth;
        .editButton {
          border: none;
          background-color: inherit;
          color: $link-blue;

          line-height: 12px;
          height: 24px;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          &:active,
          &:focus {
            color: $primary-blue;
          }
          .editButton-image {
            display: inline-block;
            vertical-align: middle;
            height: 24px;
          }

          .editButton-text {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .CompFunctionCards-header {
    height: inherit;

    border:none;
    margin: 0;
    box-shadow: none;

    font-family: Work Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    color: $primary-blue;

    .cell-image {
      cursor: default;
    }
    .cell-content {
      padding: 0;
      cursor: default;

      .information {}
      .organizers {
        height: inherit;
        line-height: inherit;
      }
    }

    .cell-right {
      margin: 0 10px 0 0;
      .dates {
        width: 100%;
        cursor: default;
      }
    }

  }

  .CompFunctionCards-footer {
    height: 40px;
    width: 100%;
    table {
      width: 100%;
      .MuiTablePagination-root {
        min-height: 40px;
        width: 100%;
        display: block;
      }
    }

    .MuiTablePagination-spacer {
      flex: 1 1 100%;
    }

    .MuiTablePagination-caption {
      flex-shrink: 0;
    }

    .MuiTablePagination-selectRoot {
      margin-left: 8px;
      margin-right: 32px;
    }
    .MuiTablePagination-input {
      color: inherit;
      font-size: inherit;
      flex-shrink: 0;
    }
    .MuiTablePagination-select {
      text-align: right;
      padding-left: 8px;
      padding-right: 24px;
      text-align-last: right;
    }
    .MuiSelect-select {
      cursor: pointer;
      min-width: 16px;
      user-select: none;
      border-radius: 0;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
    .MuiSelect-select.MuiSelect-select {
      padding-right: 24px;
    }
    .MuiInputBase-root {
      color: rgba(0, 0, 0, 0.87);
      cursor: text;
      display: inline-flex;
      position: relative;
      font-size: 1rem;
      box-sizing: border-box;
      align-items: center;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.1876em;
      letter-spacing: 0.00938em;
    }

    .MuiInputBase-input {
      font: inherit;
      color: currentColor;
      width: 100%;
      border: 0;
      height: 1.1876em;
      margin: 0;
      display: block;
      padding: 6px 0 7px;
      min-width: 0;
      background: none;
      box-sizing: content-box;
      animation-name: mui-auto-fill-cancel;
      letter-spacing: inherit;
      animation-duration: 10ms;
      -webkit-tap-highlight-color: transparent;
    }
    .MuiSelect-icon {
      top: calc(50% - 12px);
      color: rgba(0, 0, 0, 0.54);
      right: 0;
      position: absolute;
      pointer-events: none;
    }

    .MuiSvgIcon-root {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }

  }

}


.event-hours-timepicker-container {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-bottom: $inputMarginBottom;

  .MuiTextField-root {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;

    .MuiInput-formControl {
      border-radius: 1px;
      position: relative;
      background-color: $input-background;
      border: 1px solid $input-border;

      padding: 10px 12px;
      margin: 1px 0;
      &:before {
        border: 0;
      }
      &:after {
        border: 0;
      }

      &.Mui-focused {
        margin: 0;
        border-width: 2px;
        border-color: $input-border-focus;

      }

      .MuiInput-input {
        width: 100%;
        padding: 0;
        &[disabled] {
          color: $black;
        }
      }
      .MuiInputAdornment-root {
        .MuiIconButton-root {
          color:$primary-blue;
        }
      }
    }
    .MuiFormHelperText-root {
      font-family: Open Sans, sans-serif;
      display: inherit;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: $error-red;
      margin-top: 6px;
    }
  }

  .fields {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &.disabled {
    .MuiTextField-root {
      .MuiInput-formControl {
        border-color: $disabledBlueGray;
      }
    }
  }

  &.error {
    .MuiTextField-root > .MuiInput-formControl {
      border-color: $error-red;
      .MuiInputAdornment-root {
        .MuiIconButton-root {
          color:$error-red;
        }
      }

      &:focus {
        border-color: $error-red;
      }
    }
  }
}

@media only screen and (max-width: 630px) {
  .CompFunctionCards-container {
    .CompFunctionCards-row{
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 20px;

      .cell-content {
        margin: 0;
      }

      .cell-right {
        flex-wrap: nowrap;

        .dates {
          width: 100px;
        }
      }
    }
  }
}

@media only screen and (max-width: 535px) {
  .CompFunctionCards-container {
    .CompFunctionCards-row{
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 20px;

      .cell-content {
        margin: 0;
      }

      .cell-right {
        justify-content: space-between;
        flex-wrap: nowrap;

        .dates {
          width: 100px;
        }
      }
    }
  }
}

@media all and (max-width: $mobileBreakpointWidth) {
  .CompFunctionCards-container {

    .CompFunctionCards-row {
      height: inherit;
      .cell-content {
        height: 8em;
        flex-direction: column;
        .participants {
          justify-content: flex-start;
        }
        .information {
          width:100%;
          flex-direction: column;
          align-items: flex-start;
          place-content: space-evenly;
        }
      }
      .cell-right {
        width: initial;
        min-width: initial;
      }
    }

    .CompFunctionCards-header {
      display: none;
    }
  }
}