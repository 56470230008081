@import "../variables";

.comp-cards {
  .MuiTableCell-root {
    border-bottom:0;

  }
  .MuiTableCell-head {
    font-family: Work Sans, sans-serif;
  }
  .MuiTableCell-body {
    font-family: Open Sans, sans-serif;
  }

  .comp-cards-head {
    .comp-cards-head-row {
    }
    background-color: #fff;

    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    align-items: center;
    color: $primary-blue;

    margin-bottom: 10px;
    .MuiGrid-item {
      margin-bottom: 0;
    }
  }
  .comp-cards-body {
    .comp-cards-row {
      padding-bottom:10px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    }
  }
}