@import "../variables";

$button-height: 44px;
$button-width: 43px;

$scrollbarWidth: 32px;

.searchOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.search-input {
  .search-input-input {
    border: 1px solid $primary-gray;
    border-right: 0;

    width: calc(100% - #{$button-width});
    height: $button-height;
    padding-left: 16px;
    margin-top: 1px;

    > input {
      font-family: Open Sans, sans-serif;
      height: $button-height;
    }
  }

  .search-input-button {
    border: 1px solid $primary-gray;
    width: $button-width;
    height: $button-height;
    min-width: $button-width;

    margin-top: 1px;

    padding: 0;
    border-radius: unset;
    float: right;

    .search-input-button-icon-image {
    }
  }



  .search-list-container {
    position: absolute;
    z-index: 1;
    background: white;
    border: 1px solid $primary-gray;

    padding: 9px 16px 5px 18px;

    > div > span {
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: normal;

    }

    &.selected {
      background-color: $primary-blue;
      color: $white;
    }

  }

  .selected-list {
    width: 100%;
    list-style-type: none;
    box-sizing: border-box;
    position: relative;
    padding: 6px 12px 7px 12px;
    margin: 0;

    .item {
      font-family: Open Sans, sans-serif;
      float: left;

      > div {
        display: flex;
        justify-content: space-around;
        background-color: $secondary-gray;
        border-radius: 12px;
        padding: 2px 0 2px 8px;

        .closeButton {
          height: 24px;
          width: 24px;
          margin-left: auto;
          margin-right: 0;

          box-sizing: border-box;

          background-image: url('images/close.svg');
          background-repeat: no-repeat, repeat;
          background-position: left 2px top 2px, 0 0;
          background-size: 18px auto, 100%;
        }

      }


    }
  }

  &.error {
    .search-input-input {
      border-color: $error-red;
    }

    .search-input-button {
      border-color: $error-red;
    }
  }

  &.disabled {
    .search-input-input {
      display: none;
    }

    .search-input-button {
      display: none;
    }

    .selected-list {
      width: 100%;
      list-style-type: none;
      min-height: 44px;
      box-sizing: border-box;

      border-radius: 1px;
      position: relative;
      background-color: $input-background;
      border: 1px solid $disabledBlueGray;
      margin: 1px 0;


      .item {
        font-family: Open Sans, sans-serif;
        float: left;

        > div {
          display: flex;
          justify-content: space-around;
          background-color: $secondary-gray;
          border-radius: 12px;
          padding: 2px 8px 2px 8px;

          .closeButton {
            display: none;
          }

        }


      }
    }

  }
}
