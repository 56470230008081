@import "../variables";

.comp-timepicker-container {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-bottom: $inputMarginBottom;

  .MuiTextField-root {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;

    .MuiInput-formControl {
      border-radius: 1px;
      position: relative;
      background-color: $input-background;
      border: 1px solid $input-border;

      padding: 10px 12px;
      margin: 1px 0;
      &:before {
        border: 0;
      }
      &:after {
        border: 0;
      }

      &.Mui-focused {
        margin: 0;
        border-width: 2px;
        border-color: $input-border-focus;

      }

      .MuiInput-input {
        width: 100%;
        padding: 0;
        &[disabled] {
          color: $black;
        }
      }
      .MuiInputAdornment-root {
        .MuiIconButton-root {
          color:$primary-blue;
        }
      }
    }
    .MuiFormHelperText-root {
      font-family: Open Sans, sans-serif;
      display: inherit;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: $error-red;
      margin-top: 6px;
    }
  }

  &.disabled {
    .MuiTextField-root {
      .MuiInput-formControl {
        border-color: $disabledBlueGray;
      }
    }
  }

  &.error {
    .MuiTextField-root > .MuiInput-formControl {
      border-color: $error-red;
      .MuiInputAdornment-root {
        .MuiIconButton-root {
          color:$error-red;
        }
      }

      &:focus {
        border-color: $error-red;
      }
    }
  }
}
.MuiPickersModal-dialog {

  .MuiPickersDay-daySelected {
  background-color: $primary-blue;
  }
  .MuiDialogActions-root {
    button {
      color: $primary-blue;
    }
  }
}
