@import "../variables";

.comp-radio-button-group {
  .MuiRadio-root {
    color: $input-border;

    &.Mui-checked {
      color: $input-border-focus;
    }

    .MuiSvgIcon-root {
      font-size: 30px;
    }
    &.Mui-disabled {
      .MuiSvgIcon-root {
        color:$disabled-gray;
      }
    }
  }

  .MuiFormControlLabel-label {
    color: $black;
    font-family: Open Sans, sans-serif;
    &.Mui-disabled {
      color: $black;
    }
  }

  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.error {
    .MuiSvgIcon-root {
      color: $error-red;
    }

    .MuiIconButton-colorSecondary:hover {
      background-color: rgba(245, 0, 87, 0.04);
    }
  }

}


.large-comp-radio-button-group {
  .MuiRadio-root {
    color: $input-border;

    &.Mui-checked {
      color: $input-border-focus;
    }

    .MuiSvgIcon-root {
      height: 50px;
      width: 50px;
      font-size: 30px;
    }
    &.Mui-disabled {
      .MuiSvgIcon-root {
        color:$disabled-gray;
      }
    }
  }

  .MuiFormControlLabel-label {
    font-size: 20px;
    color: $black;
    font-family: Open Sans, sans-serif;
    &.Mui-disabled {
      color: $black;
    }
  }

  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.error {
    .MuiSvgIcon-root {
      color: $error-red;
    }

    .MuiIconButton-colorSecondary:hover {
      background-color: rgba(245, 0, 87, 0.04);
    }
  }

}