@import "../variables";

$formMaxWidth: 450px;

.loginRoot {
  height: 100vh;
  padding-top: 0;
  .loginBackgroundImage {
    background-image: url("images/PictLogin_72ppi.png");
    background-repeat: no-repeat;
    //backgroundColor:
    background-size: cover;
    background-position: center;
  }
  .feedback {
    padding-top: 8px;
  }
  .langChange {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  .high {
    background-image: url("images/PictLogin_72ppi.png");
    height: 100vh;
  }
  .mid {
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
  }
  .align-right {
    text-align: end;
  }
  .title {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;

    color: $primary-blue;
    text-align: center;
    margin: 0 0 32px 0;
  }

  .paper {
    padding-top: 10%;
    padding-bottom: 10%;

    align-items: center;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .paperHeader {
      .appLogo {
        max-width: 100%;
      }
    }
    .paperContent {
      height: 60%;

      .title {
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;

        color: $primary-blue;
        text-align: center;
        margin: 0 0 32px 0;
      }
      .MuiAlert-root {
        width: 100%;
      }
      .form {
        width: 100%;
        max-width: $formMaxWidth;
        margin: 0 0 32px 0;
        .submit {
          margin: 1rem 0 0.5rem 0;
        }
        .forgotPass {
          color: $primary-blue;
        }
      }
    }
    .paperFooter {
      display: flex;
      width: 100%;
      justify-content: space-between;
      max-width: $formMaxWidth;

      p {
        align-content: center;
      }

      img {
        max-width: 185px;
      }
    }
  }
}
@media all and (max-width: $mobileBreakpointWidth) {
  .loginRoot {
    .paper {
      .paperFooter {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
