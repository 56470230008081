@import "../variables";

.comp-text-field-container {
  display: inline-block;
  width: 100%;
  padding:0;
  margin-bottom: $inputMarginBottom;

  .label {
    font-family: Open Sans, sans-serif;
  }

  div {
    width: 100%;
    input,
    textarea {
      width: 100%;
      border-radius: 1px;
      position: relative;
      background-color: $input-background;
      border: 1px solid $input-border;

      padding: 10px 12px;
      margin: 1px 0;

      &[disabled] {
        border-color: $disabledBlueGray;
        color: $black;
      }

      &:focus {
        margin: 0;
        border-width: 2px;
        border-color: $input-border-focus;
      }
    }
  }

  &.error {
    div > input,
    div > textarea {
        border-color: $error-red;

        &:focus {
          border-color: $error-red;
        }
      }
  }

}
@media all and (max-width: $mobileBreakpointWidth) {
  
}