@import "../variables";

$noseBorderWidth: 12px;
.reports {
  .reportMapContainer {
    margin-left: -40px;
    margin-right: 40px;
  }

  .listComponent {
    min-height: 430px;

    .search-list-container {
      max-height: 372px;
    }
  }

  .wrapperDoc {
    display: flex
  }

  .sliderContainer {
    padding: 0 20px;

    .label {
      margin-left: -20px;
    }

    .MuiSlider-root {
      .MuiSlider-thumb {
        width: 24px;
        height: 24px;
        margin-top: -11px;
        margin-left: -11px;

        background-color: $primary-blue;

        .MuiSlider-valueLabel {
          > span {
            background-color: $primary-blue;
            margin-left: 12px;
          }
        }
      }

      .MuiSlider-mark {
        width: 24px;
        height: 24px;
        margin-top: -12px;
        margin-left: -12px;

        border-radius: 50%;
        border: 1px solid $primary-blue;
        background-color: $white;

        &.MuiSlider-markActive {

        }
      }

      .MuiSlider-rail {
        height: 24px;
        margin-top: -12px;
        margin-left: 0;

        border-radius: 1px;
        background-color: $secondary-blue;
      }
    }
  }

  .balloon-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 23px;

    .balloon-notification-container {
      flex: 0 1;

      .balloon-notification-top {
        .title {
          min-width: 130px;
          justify-content: center;

          font-family: Work Sans, sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 36px;
          line-height: 42px;

          &::after {
            left: calc(50% - #{$noseBorderWidth});
          }
        }
      }

      .content {
        font-family: Work Sans, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height, or 129% */

        text-align: center;
        text-transform: uppercase;
        margin-top: 3px;
        justify-content: center;
        min-width: -webkit-fill-available;
        max-width: fit-content;
        width: auto;
      }
    }
  }

  .column-box-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 23px;

    .column-box-title {
      background: $blueGray;
      height: 65px;

      font-family: Work Sans, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      /* or 125% */

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-transform: uppercase;

      color: #FFFFFF;

    }

    .column-box-content {
      background: rgba(115, 137, 157, 0.1);
      padding: 32px;
    }
  }

  &.dataQueryPage {
    .comp-radio-button-group {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}


@media all and (max-width: $mobileBreakpointWidth) {
  .reports {
    .reportMapContainer {
      margin-left: initial;
      margin-right: initial;
    }
  }
}