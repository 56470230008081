@import "variables";



.leftDrawer {
  width: $drawerWidth;
  flex-shrink: 0;
  .leftDrawerPaper {
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
    background-color: $main-background-color;
    margin-left: 10px;
    border-right: none;
    margin-top:$appBarHeight;

    .leftDrawer-title {
      width: $drawerWidth;
      background: $primary-blue;
      border: 1px solid #fff;
      box-sizing: border-box;
      border-top-left-radius: $drawerTitleBorderRadius;
    }

    .left-drawer-list {
      > div {
        background-color: $white;
        border-right: 1px solid $white;
      }

      .leftListItemLink {
        border-color: $primary-blue;
        background-color: $white;
        border-right: 10px solid $white;
        padding-left: 20px;
        &:hover {
          background-color: $secondary-gray;
          // rgba(255, 255, 255,0.65);
          border-right: 10px solid $primary-gray;
        }
        &:active,
        &:focus {
          color: $focus-font-color;
          background-color: $focus-background-color;
          border-right: 0;
        }
        // subclasses
        &.active {
          border-right: 10px solid $red;
        }
      }
    }
  }
}





