@import "../variables";

$buttonWidth:44px;

.comp-numeric-up-down-field-container {

  display: inline-block;
  width: 100%;
  padding:0;
  margin-bottom: $inputMarginBottom;

  .label {
    font-family: Open Sans, sans-serif;
  }
  .fieldContainer {
    border: 1px solid $input-border;
    border-radius: 1px;

    .MuiInputBase-root {
      width: calc(100% - #{$buttonWidth} - #{$buttonWidth});
      input,
      textarea {
        width: 100%;
        position: relative;
        background-color: $input-background;

        padding: 10px 12px;
        margin: 1px 0;

        &[disabled] {
          border-color: $disabledBlueGray;
          color: $black;
        }

        &:focus {
          margin: 0;
        }
      }
    }
  }
  .button {
    height: 42px;
    width: $buttonWidth;
    min-width: $buttonWidth;

    background-color: $input-background;

    border-radius: 0;

    &[disabled] {
      border-color: $disabledBlueGray;
      color: $black;
    }

    &.minusButton {
      border-left: 1px solid $input-border;
      border-right: 1px solid $input-border;

    }
    &.plusButton {
      border-left: 1px solid $input-border;
    }

    .MuiButton-label {
      width: $buttonWidth;
    }
  }

  &.focused {
    .fieldContainer {
      border-width: 2px;
      border-color: $input-border-focus;
      button {
        border-color: $input-border-focus;
        //margin: 0;
        //border-width: 2px;
      }
    }
  }

  &.error {
    .fieldContainer {
      border-color: $error-red;
      div > input,
      div > textarea {
        border-color: $error-red;

        &:focus {
          border-color: $error-red;
        }
      }

      button {
        border-color: $error-red;
      }
    }
  }

}
@media all and (max-width: $mobileBreakpointWidth) {
  
}