@import "../variables";
@import "../font";


$normal-font-color: $primary-blue;


.comp-button {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  box-shadow: none;
  padding: 24px 9px;
  height: 48px;

  margin: 6px 10px;
  &::first-line {
    margin-top:0;
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: none;
  }
}

.button-main {
  background-color: $secondary-blue;
  color: $normal-font-color;
  border: 2px solid $secondary-blue;

  &:hover {
    background-color: $secondary-blue;
    border: 2px solid $focus-background-color;
  }

  &:active,
  &:focus {
    background-color: $focus-background-color;
    color: $focus-font-color;
    border: 2px solid $focus-background-color;

  }
  &[disabled] {
    background-color: $disabled-blue;
    color: $disabled-blue-link;
    border: 2px solid $disabled-blue;
  }
}

.button-secondary {
  background-color: $white;
  color: $normal-font-color;
  border: 2px solid $secondary-blue;

  &:hover {
    background-color: $white;
    border: 2px solid $focus-background-color;
  }

  &:active,
  &:focus {
    background-color: $focus-background-color;
    color: $focus-font-color;
    border: 2px solid $focus-background-color;
  }
  &[disabled] {
    background-color: $white;
    color: $disabled-blue-link;
    border: 2px solid $disabled-blue;
  }
}

.button-attention {
  background-color: $primary-yellow;
  color: $normal-font-color;
  border: 2px solid $primary-yellow;

  &:hover {
    background-color: $primary-yellow;
    border: 2px solid $focus-background-color;
  }

  &:active,
  &:focus {
    background-color: $focus-background-color;
    color: $focus-font-color;
    border: 2px solid $focus-background-color;
  }
  &[disabled] {
    background-color: $disabled-yellow;
    color: $disabled-blue-link;
    border: 2px solid $disabled-yellow;
  }
}
