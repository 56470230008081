@import "../variables";

$main-color: $primary-blue;
$contrast-color: $white;
$border-color: $secondary-blue;

.show-part-selector {
  margin:0 0 24px 0;
  width: 100%;

  .label-container {
    padding-bottom: 0;
    label {
      color: $main-color;
    }
  }
  .button-container-box {
    border: 2px solid $border-color;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .button-container {
      margin-bottom: 0;

      flex-grow: 1;
      .button{
        box-sizing: border-box;
        height: 44px;
        width:100%;
        color: $main-color;
        text-transform: none;
      }
      &.active {
        background-color: $main-color;
        border: 1px solid $contrast-color;
        .button{
          width:100%;
          color: $contrast-color;
          text-transform: none;
        }
      }
    }

  }
}