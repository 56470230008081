.requests-cards {

  .icon-item {
    padding: 0 7px 0 0;
    width: 84px;
  }

  .comp-cards-head {
    .comp-cards-head-row {
      th {
        padding: 0;
      }
    }
  }
  .comp-cards-row {
    td {
      padding: 0;
    }

    .icon-item {
      height: 66px;
    }

    p {
      margin:0;
    }
    .action-icon-img {
      max-width: 84px;
      min-height: 66px;
    }
  }
}