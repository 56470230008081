@import "variables";
@import "font";

$mainSidePadding: 20px; //40px;
$columnSpace: 11px;
$containerMargin: 10px;

html, body {
  height: auto;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: $black;
}

input, select,
.MuiInputBase-input,
.MuiTableCell-root,
.MuiTypography-body1,
.MuiTypography-body2 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

select {
  min-height: 44px;
}

@media(min-width: 768px) {
  .reports {
    
  }
}

.MuiInputBase-input {
  min-height: 22px;
}

.MuiFormLabel-root {
  font-family: Open Sans, sans-serif;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: $input-label;
}

.MuiTypography-body1 {
  color: inherit;
}

.MuiButton-root {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}


#root {
}

.root {
  display: flex;
  background-color: $main-background-color;
}

.contentsDiv {
  display: contents;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.bold {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.align-right {
  text-align: end;
}

.mainPageText {
  overflow-wrap: anywhere;
}

.appBar {
  z-index: 1201;
  box-shadow: none;
}

.error-label {
  font-family: Open Sans, sans-serif;
  display: none;
}

.error {
  .error-label {
    display: inherit;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: $error-red;
    margin-top: 6px;
  }
}

.MuiAlert-root {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 12px;
}

.MuiAlert-standardError {
  color: $primary-blue;
  background-color: $alert-error-bg;
}

.MuiAlert-standardWarning {
  color: $primary-blue;
  background-color: $alert-warning-bg;
}

.MuiAlert-standardInfo {
  color: $primary-blue;
  background-color: $alert-info-bg;
}

.MuiAlert-standardSuccess {
  color: $primary-blue;
  background-color: $alert-success-bg;
}

.clear {
  clear: both;
}

.large-text {
  font-size: 18px;
}

.label,
.MuiSlider-markLabel {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: $input-label;
  margin-bottom: 0;
}

.MuiAppBar-colorPrimary {
  color: $white;
  background-color: $main-background-color;

  .appBarToolbar {
    width: 100%;
    height: $appBarHeight;
    background: $main-background-color;
    padding-left: 0;
    padding-right: 20px;

    .logo-container {
      padding-left: 0.8rem;
      width: $drawerWidth;
      display: inline-block;

      .header-logo-normal {
        // these are predefined in Mui styles so we cannot use rem
        padding-left: 16px;
        padding-right: 16px;
        display: block;
      }

    }

    a.appBar-link,
    a.appBar-link:visited {
      color: $white;
      text-decoration: none;

      height: $appBarHeight;
      line-height: $appBarHeight;
      display: inline-block;

      &:hover {
        background-color: $secondary-gray;
        color: $primary-blue;
        border-bottom: 10px solid $primary-gray;
      }

      &:active,
      &:focus {
        background-color: $primary-gray;
        color: $white;
        border-bottom: 0;
      }
    }

    .breadcrumb-container {
      padding-top: 0;
      text-align: center;

      .breadcrumb {
        font-family: Work Sans, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        color: $white;
        margin: auto;

        padding-left: $columnSpace;
        padding-right: $columnSpace;

        display: inline-block;
        vertical-align: middle;

        a, a:visited {
          padding-left: 6px;
          padding-right: 6px;
        }
      }
    }

    .language-selector {
      margin-left: 15px;
      margin-right: 15px;

      font-family: Work Sans, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1.1rem;

      padding-left: 15px;
      padding-right: 15px;
      text-transform: uppercase;

      cursor: pointer;
    }

    .userMenuButton {
      color: $white;
      background-color: $main-background-color;
    }

    .user-icon {
      height: 54px;

      img {
        height: 54px;
      }
    }

    .userMenu {
      position: absolute;
      top: 0;
      right: 0;

      width: 290px;

      background-color: $white;
      color: $primary-blue;
      padding: 0 30px 12px 30px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 30px;

      z-index: 1;

      .userMenu-icon {
        position: fixed;
        top: 10px;
        right: 2px;

        &:hover {
          background-color: initial;
        }
      }

      .userMenu-name {
        margin-bottom: 0;
      }

      .userMenu-menu {
        list-style: none;
        margin: 0;
        padding: 0;

        > li > button,
        > li > a {
          border: 1px solid $white;

          &:hover {
            border: 1px solid $primary-blue;
            background-color: initial;
          }

          span {
            font-family: Open Sans, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 30px;

            text-transform: none;
            color: $primary-blue;
          }
        }

        .MuiSvgIcon-root,
        .userMenu-menu-icon-img {
          width: 24px;
          height: 24px;
          margin-right: 6px;
        }
      }
    }

    .userMenuOverlay {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      display: inherit;

    }
  }

  .appBarToolbarMobile {
    padding-bottom: 0;
    padding-left: 9px;
    min-height: auto;
    height: $mobileAppBarHeight;
    border-top: 1px solid $white;

    .logo-container {
      padding-left: 0.8rem;
      width: $drawerWidth;
      display: inline-block;

      .header-logo-mobile {
        display: block;
      }
    }
  }


}

.container {
  margin-left: $containerMargin;
  width: calc(100% - #{$drawerWidth} - #{$rightMargin});
  padding: $titleHeight $mainSidePadding 0 $mainSidePadding;

  .bottom-button-container {
    padding-top: 1.5rem;
  }
}

.drawerToolbar {
  min-height: 5.4rem;
}

.drawer-title {
  height: $titleHeight;
  // these are predefined in Mui styles so we cannot use rem
  padding-left: 20px;
  padding-right: 16px;

  span {
    font-family: Work Sans, sans-serif;;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 45px;
    color: $white;
  }
}

/** appBarSpacer: theme.mixins.toolbar;**/
.drawer-list {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;

  > div {
    height: 100%;
  }
}

.listItemLink {
  /*height: 4.9rem;*/
  height: 78px;
  border-radius: 0;
  border-top: 0;
  border-bottom: 1px solid;
  border-left: 0;
  border-right: 0;
  color: $primary-blue;

  padding-left: 16px;
  padding-right: 16px;

  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;


  > div {
    margin-top: 7px;

    > span {

      font-family: Work Sans, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1.1rem;
    }
  }

  .notification-balloon {
    background-color: $primary-yellow;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;

    font-family: Work Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    color: $primary-blue;
  }
}

main {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  margin-right: $rightMargin;
  padding-top: $appBarHeight;

  &.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .container {
      flex: 1 1 auto;
    }
  }
}

.MuiGrid-container {

}

.MuiGrid-item,
.MuiGrid-item-label {
  padding-left: $columnSpace;
  padding-right: $columnSpace;
}

.MuiGrid-item {
  margin-bottom: 10px;
  width: calc(100% + 10px);
}

.footerToolbar {
  align-items: center;
  width: 100%;
  padding-left: $mainSidePadding;

  a, a:visited {
    color: $primary-blue;
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.2rem;

    height: 2rem;
    line-height: 2rem;

    &:hover {
      text-decoration: none;
      color: $primary-gray;
    }

    &:active,
    &:focus {
      background-color: $focus-background-color;
      color: $focus-font-color;
      border-radius: 15px;
    }
  }

  > .MuiGrid-item {
    background-color: $white;

    > div {
    }

    > a {
    }
  }

  .footer-links {
    .link-data-privacy {
    }

    .link-accessibility {
    }
  }

  .copyright {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .footer-logo {
    width: $drawerWidth;
    text-align: right;
    padding-right: calc(7px + #{$rightMargin});
    background-color: unset;

    img {
      height: $footerLogoHeight;
    }
  }
}

.modal {
  position: absolute;
  top: calc(#{$appBarHeight} + 1px);
  left: calc(#{$drawerWidth} + #{$containerMargin});

  width: calc(100% - #{$drawerWidth} - #{$containerMargin} - #{$containerMargin} - #{$drawerWidth});
  max-height: calc(100vh - (#{$appBarHeight} + 1px));

  padding: 32px $mainSidePadding 0 $mainSidePadding;

  background-color: $content-background-color;
  overflow: auto;
}

.archiveModal {
  height: inherit;
  padding: 0;
  left: calc((100vw - 550px) / 2);
  width: 550px;
  border-radius: 8px;

  .modalDescription {
    padding: 28px;
    background-color: $notificationYellow;
    font-weight: 600;
  }

  .modalQuestion {
    padding: 28px;
    text-align: center;
  }

  .modalButtons {
    padding-bottom: 28px;
    text-align: center;

    button {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}

@media all and (max-width: $mobileBreakpointWidth) {
  .modal {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: none;
  }

}

@import "left_drawer";
@import "right_drawer";