@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/WorkSans-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  src: url("fonts/WorkSans-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  src: url("fonts/WorkSans-Bold.otf") format("opentype");
}
/** COMPONENTS **/
.MuiAccordion-root .Mui-disabled {
  background-color: #ffffff;
}

.MuiAccordionSummary-root .Mui-disabled {
  opacity: 1;
}

.locationInfoCtr {
  width: 100% !important;
}

.comp-accordion-container {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: none;
  margin-bottom: 8px;
}
.comp-accordion-container .comp-accordion-summary {
  height: 48px;
  min-height: 48px;
  border: 2px solid #7DC6F0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}
.comp-accordion-container .comp-accordion-summary .header-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #0E4264;
}
.comp-accordion-container .comp-accordion-summary .header-text .comp-accordion-error {
  display: none;
}
.comp-accordion-container .comp-accordion-summary .MuiAccordionSummary-expandIcon.Mui-expanded {
  color: #ffffff;
}
.comp-accordion-container .comp-accordion-summary.Mui-expanded {
  background-color: #0E4264;
  border-color: #0E4264;
}
.comp-accordion-container .comp-accordion-summary.Mui-expanded .header-text {
  color: #ffffff;
}
.comp-accordion-container .comp-accordion-summary.Mui-focused {
  border: 2px solid #0E4264;
  background-color: #ffffff;
}
.comp-accordion-container .comp-accordion-summary.Mui-focused .header-text {
  color: #0E4264;
}
.comp-accordion-container .comp-accordion-summary.Mui-focused .MuiAccordionSummary-expandIcon {
  color: #0E4264;
}
.comp-accordion-container .comp-accordion-summary.Mui-disabled {
  border: 2px solid #B1DDF6;
}
.comp-accordion-container .comp-accordion-summary.Mui-disabled .header-text {
  color: #69899C;
}
.comp-accordion-container .comp-accordion-summary.Mui-disabled .MuiAccordionSummary-expandIcon {
  color: #69899C;
}
.comp-accordion-container .comp-accordion-details {
  padding: 24px 0 0 0;
  /* >.MuiGrid-container {
    margin-left: -11px;
    margin-right: -11px;
    width: auto;
  } */
}
.comp-accordion-container .comp-accordion-details .MuiGrid-item {
  margin-bottom: 0;
}
.comp-accordion-container .comp-accordion-details .MuiGrid-item .MuiGrid-container {
  margin: 0 0 0 -11px;
  width: calc(100% + 11px + 11px);
}
.comp-accordion-container.error .comp-accordion-summary .header-text > .comp-accordion-error {
  display: inline;
  margin-top: 0;
  margin-left: 5px;
  padding: 5px 8px;
  border-radius: 8px;
  background-color: #ffffff;
}

.balloon-notification-container {
  display: flex;
  flex-direction: column;
}
.balloon-notification-container .balloon-notification-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}
.balloon-notification-container .balloon-notification-top .title {
  background: #0E4264;
  border-radius: 32px;
  padding: 16px;
  overflow: initial;
  position: relative;
  max-height: 58px;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 58px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.balloon-notification-container .balloon-notification-top .title .icon-image {
  margin: -12px 0 -12px -12px;
  max-height: 54px;
  max-width: 65px;
}
.balloon-notification-container .balloon-notification-top .title::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  top: 100%;
  right: 25px;
  border-bottom: none;
  border-top-color: #0E4264;
}
.balloon-notification-container .balloon-notification-top .title.notification {
  background: #FFC0D0;
  color: #0E4264;
}
.balloon-notification-container .balloon-notification-top .title.notification::after {
  border-top-color: #FFC0D0;
}
.balloon-notification-container .balloon-notification-top .title.reminder {
  background: #FFE561;
  color: #0E4264;
}
.balloon-notification-container .balloon-notification-top .title.reminder::after {
  border-top-color: #FFE561;
}
.balloon-notification-container .balloon-notification-top .extra-elements {
  display: flex;
  flex-direction: column;
}
.buttonSpacer {
  margin: -6px -10px 0 -10px;
  margin-bottom: 24px;
  padding-bottom: 0;
  padding-top: 20px;
}
.buttonSpacer .comp-button {
  padding: 21px 9px 20px 9px;
  margin: 5px 10px;
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/WorkSans-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  src: url("fonts/WorkSans-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  src: url("fonts/WorkSans-Bold.otf") format("opentype");
}
.comp-button {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  box-shadow: none;
  padding: 24px 9px;
  height: 48px;
  margin: 6px 10px;
}
.comp-button::first-line {
  margin-top: 0;
}
.comp-button:active, .comp-button:focus, .comp-button:hover {
  box-shadow: none;
}

.button-main {
  background-color: #7DC6F0;
  color: #0E4264;
  border: 2px solid #7DC6F0;
}
.button-main:hover {
  background-color: #7DC6F0;
  border: 2px solid #0E4264;
}
.button-main:active, .button-main:focus {
  background-color: #0E4264;
  color: #ffffff;
  border: 2px solid #0E4264;
}
.button-main[disabled] {
  background-color: #B1DDF6;
  color: #69899C;
  border: 2px solid #B1DDF6;
}

.button-secondary {
  background-color: #ffffff;
  color: #0E4264;
  border: 2px solid #7DC6F0;
}
.button-secondary:hover {
  background-color: #ffffff;
  border: 2px solid #0E4264;
}
.button-secondary:active, .button-secondary:focus {
  background-color: #0E4264;
  color: #ffffff;
  border: 2px solid #0E4264;
}
.button-secondary[disabled] {
  background-color: #ffffff;
  color: #69899C;
  border: 2px solid #B1DDF6;
}

.button-attention {
  background-color: #FFE561;
  color: #0E4264;
  border: 2px solid #FFE561;
}
.button-attention:hover {
  background-color: #FFE561;
  border: 2px solid #0E4264;
}
.button-attention:active, .button-attention:focus {
  background-color: #0E4264;
  color: #ffffff;
  border: 2px solid #0E4264;
}
.button-attention[disabled] {
  background-color: #FCF1B9;
  color: #69899C;
  border: 2px solid #FCF1B9;
}

.comp-cards .MuiTableCell-root {
  border-bottom: 0;
}
.comp-cards .MuiTableCell-head {
  font-family: Work Sans, sans-serif;
}
.comp-cards .MuiTableCell-body {
  font-family: Open Sans, sans-serif;
}
.comp-cards .comp-cards-head {
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #0E4264;
  margin-bottom: 10px;
}
.comp-cards .comp-cards-head .MuiGrid-item {
  margin-bottom: 0;
}
.comp-cards .comp-cards-body .comp-cards-row {
  padding-bottom: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}

.comp-checkbox-group .MuiFormControlLabel-root .MuiCheckbox-root {
  color: #63798D;
}
.comp-checkbox-group .MuiFormControlLabel-root .MuiCheckbox-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.comp-checkbox-group .MuiFormControlLabel-root .MuiCheckbox-root .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
}
.comp-checkbox-group .MuiFormControlLabel-root .MuiCheckbox-root.Mui-checked {
  color: #0E4264;
}
.comp-checkbox-group .MuiFormControlLabel-root .MuiCheckbox-root.Mui-disabled .MuiSvgIcon-root {
  color: #9FB3C1;
}
.comp-checkbox-group.error .MuiSvgIcon-root {
  color: #993366;
}
.comp-checkbox-group.error .MuiSvgIcon-root:hover {
  background-color: rgba(245, 0, 87, 0.04);
}
.comp-checkbox-group.error .MuiIconButton-colorSecondary:hover {
  background-color: rgba(245, 0, 87, 0.04);
}
.comp-checkbox-group .MuiFormControlLabel-label {
  color: #000;
  font-family: Open Sans, sans-serif;
}
.comp-checkbox-group .MuiFormControlLabel-label.Mui-disabled {
  color: #000;
}
.comp-checkbox-group .checkbox-row {
  display: flex;
}

.comp-datepicker-container {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-bottom: 24px;
}
.comp-datepicker-container .MuiTextField-root {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}
.comp-datepicker-container .MuiTextField-root .MuiInput-formControl {
  border-radius: 1px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #63798D;
  padding: 10px 12px;
  margin: 1px 0;
}
.comp-datepicker-container .MuiTextField-root .MuiInput-formControl:before {
  border: 0;
}
.comp-datepicker-container .MuiTextField-root .MuiInput-formControl:after {
  border: 0;
}
.comp-datepicker-container .MuiTextField-root .MuiInput-formControl.Mui-focused {
  margin: 0;
  border-width: 2px;
  border-color: #0E4264;
}
.comp-datepicker-container .MuiTextField-root .MuiInput-formControl .MuiInput-input {
  width: 100%;
  padding: 0;
}
.comp-datepicker-container .MuiTextField-root .MuiInput-formControl .MuiInput-input[disabled] {
  color: #000;
}
.comp-datepicker-container .MuiTextField-root .MuiInput-formControl .MuiInputAdornment-root .MuiIconButton-root {
  color: #0E4264;
}
.comp-datepicker-container .MuiTextField-root .MuiFormHelperText-root {
  font-family: Open Sans, sans-serif;
  display: inherit;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #993366;
  margin-top: 6px;
}
.comp-datepicker-container.disabled .MuiTextField-root .MuiInput-formControl {
  border-color: #C7D0D8;
}
.comp-datepicker-container.error .MuiTextField-root > .MuiInput-formControl {
  border-color: #993366;
}
.comp-datepicker-container.error .MuiTextField-root > .MuiInput-formControl .MuiInputAdornment-root .MuiIconButton-root {
  color: #993366;
}
.comp-datepicker-container.error .MuiTextField-root > .MuiInput-formControl:focus {
  border-color: #993366;
}

.MuiPickersModal-dialog .MuiPickersDay-daySelected {
  background-color: #0E4264;
}
.MuiPickersModal-dialog .MuiDialogActions-root button {
  color: #0E4264;
}
.MuiPickersModal-dialog .MuiPickersCalendarHeader-dayLabel {
  color: #0E4264;
}

.comp-dropdown-container {
  width: 100%;
  margin-bottom: 24px;
  padding: 0;
  height: 44px;
}
.comp-dropdown-container.error select {
  border-color: #993366;
}
.comp-dropdown-container.error select:focus {
  border-color: #993366;
}
.comp-dropdown-container .MuiFormControl-root.error .dropdown-select {
  border-color: #993366;
}

.dropdown-select {
  display: block;
  height: inherit;
  box-sizing: border-box;
  margin: 1px 0;
  border: 1px solid #63798D;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 1px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 12px;
  background-color: #ffffff;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  line-height: 1.3;
}
.dropdown-select ::-ms-expand {
  display: none;
}
.dropdown-select[disabled] {
  border-color: #C7D0D8;
  color: #000;
  cursor: default;
}
.dropdown-select:focus {
  box-shadow: none;
  outline: none;
  margin: 0;
  border-width: 2px;
  border-color: #0E4264;
}
.dropdown-select option {
  background-color: #ffffff;
  font-weight: normal;
}
.dropdown-select option:checked, .dropdown-select option:hover {
  background-color: #0E4264;
  color: #ffffff;
}
.dropdown-select .functionCardPaginator {
  padding-right: 24px;
}
.dropdown-select .MuiTablePagination-select {
  padding-right: 24px;
}
.dropdown-select .MuiSelect-selectMenu {
  padding-right: 5px !important;
}

.input-list-container {
  box-sizing: border-box;
}
.input-list-container .input-list {
  border: 1px solid #63798D;
  /* ROWS */
}
.input-list-container .input-list[disabled] {
  border-color: #C7D0D8;
}
.input-list-container .input-list[disabled] .input-list-row.input-list-row-total {
  border-top: 1px solid #C7D0D8;
}
.input-list-container .input-list .input-list-row {
  height: 43px;
  /* row value */
  /* TOTAL */
}
.input-list-container .input-list .input-list-row > div {
  display: inline-block;
  height: 43px;
}
.input-list-container .input-list .input-list-row .input-list-row-label {
  width: calc(100% - 62px - 1px * 2);
  padding-left: 9px;
}
.input-list-container .input-list .input-list-row.input-list-row-0 .input-list-row-value {
  border-top: 0;
}
.input-list-container .input-list .input-list-row .input-list-row-value {
  width: 62px;
  border: 0;
  border-top: 1px solid #C7D0D8;
  border-left: 1px solid #C7D0D8;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}
.input-list-container .input-list .input-list-row .input-list-row-value input {
  line-height: 19px;
  font-family: Open Sans, sans-serif;
  background-color: #ffffff;
  width: 62px;
  height: calc(43px - 1px);
  border: 0;
  padding-left: 10px;
}
.input-list-container .input-list .input-list-row .input-list-row-value input[disabled] {
  border-color: #C7D0D8;
  color: #000;
}
.input-list-container .input-list .input-list-row .input-list-row-value input:focus {
  margin: 0;
  border: 3px solid #0E4264;
}
.input-list-container .input-list .input-list-row.input-list-row-total {
  border-top: 1px solid #63798D;
}
.input-list-container .input-list .input-list-row.input-list-row-total .input-list-row-label {
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  padding-top: 13px;
  padding-bottom: 13px;
}
.input-list-container .input-list .input-list-row.input-list-row-total .input-list-row-value {
  border: 0;
  padding-left: 10px;
  font-family: Open Sans, sans-serif;
}
.input-list-container.error .input-list {
  border-color: #993366;
}
.input-list-container.error .input-list .input-list-row-value input {
  border-color: #993366;
}
.input-list-container.error .input-list .input-list-row-value input:focus {
  border-color: #993366;
}
.input-list-container.error .input-list .input-list-row-total {
  border-top-color: #993366;
}

.list-tree {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  border: 1px solid #63798D;
}
.list-tree.disabled {
  border-color: #C7D0D8;
}
.list-tree.disabled .MuiListItem-root.Mui-disabled {
  opacity: 1;
}
.list-tree.disabled .MuiListItem-root.Mui-disabled .tree-opener-icon {
  opacity: 0.5;
}
.list-tree .list-tree-title {
  background-color: #63798D;
  line-height: 1.25rem;
  padding: 12px 13px 7px 20px;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}
.list-tree .list-tree-container {
  padding-top: 0;
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.list-tree .list-tree-container .list-tree-item {
  padding: 9px 16px 5px 82px;
  border-top: 1px solid #C7D0D8;
}
.list-tree .list-tree-container .list-tree-item > div > span {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
}
.list-tree .list-tree-container .list-tree-item.selected {
  background-color: #0E4264;
  color: #ffffff;
}
.list-tree .list-tree-container .list-tree-item .tree-opener-icon {
  position: absolute;
  top: 0;
  left: 18px;
  padding: 4px 8px;
}
.list-tree .list-tree-container .list-tree-item-1 {
  padding-left: calc(82px + 20px * 1);
}
.list-tree .list-tree-container .list-tree-item-1 .tree-opener-icon {
  left: calc(18px + 20px * 1);
}
.list-tree .list-tree-container .list-tree-item-2 {
  padding-left: calc(82px + 20px * 2);
}
.list-tree .list-tree-container .list-tree-item-2 .tree-opener-icon {
  left: calc(18px + 20px * 2);
}
.list-tree .list-tree-container .list-tree-item-3 {
  padding-left: calc(82px + 20px * 3);
}
.list-tree .list-tree-container .list-tree-item-3 .tree-opener-icon {
  left: calc(18px + 20px * 3);
}
.list-tree .list-tree-container .list-tree-item-4 {
  padding-left: calc(82px + 20px * 4);
}
.list-tree .list-tree-container .list-tree-item-4 .tree-opener-icon {
  left: calc(18px + 20px * 4);
}
.list-tree .list-tree-container .list-tree-item-5 {
  padding-left: calc(82px + 20px * 5);
}
.list-tree .list-tree-container .list-tree-item-5 .tree-opener-icon {
  left: calc(18px + 20px * 5);
}
.list-tree .list-tree-container .list-tree-item-6 {
  padding-left: calc(82px + 20px * 6);
}
.list-tree .list-tree-container .list-tree-item-6 .tree-opener-icon {
  left: calc(18px + 20px * 6);
}
.list-tree .list-tree-container .list-tree-item-7 {
  padding-left: calc(82px + 20px * 7);
}
.list-tree .list-tree-container .list-tree-item-7 .tree-opener-icon {
  left: calc(18px + 20px * 7);
}
.list-tree .list-tree-container .list-tree-item-8 {
  padding-left: calc(82px + 20px * 8);
}
.list-tree .list-tree-container .list-tree-item-8 .tree-opener-icon {
  left: calc(18px + 20px * 8);
}
.list-tree .list-tree-container .list-tree-item-9 {
  padding-left: calc(82px + 20px * 9);
}
.list-tree .list-tree-container .list-tree-item-9 .tree-opener-icon {
  left: calc(18px + 20px * 9);
}
.list-tree .list-tree-container::-webkit-scrollbar {
  width: 32px;
}
.list-tree .list-tree-container::-webkit-scrollbar-track {
  background: #ffffff;
  border: solid 20px pink;
}
.list-tree .list-tree-container::-webkit-scrollbar-track-piece {
  background: #ffffff;
}
.list-tree .list-tree-container::-webkit-scrollbar-thumb {
  background: #C7D0D8;
  border: 9px solid #ffffff;
}
.list-tree .list-tree-container::-webkit-scrollbar-thumb:hover {
  background: #63798D;
}
.list-tree.error .error-label {
  padding: 0 13px 7px 20px;
}

.comp-multiselect-dropdown-container {
  width: 100%;
  margin-bottom: 24px;
}
.comp-multiselect-dropdown-container.error .comp-multiselect-dropdown .input-link:focus .selected-list {
  border-color: #993366;
}
.comp-multiselect-dropdown-container.error .comp-multiselect-dropdown .selected-list {
  border-color: #993366;
}
.comp-multiselect-dropdown-container.error .comp-multiselect-dropdown .selected-list.open {
  border-color: #993366;
}
.comp-multiselect-dropdown-container.disabled .comp-multiselect-dropdown .input-link {
  cursor: default;
}
.comp-multiselect-dropdown-container.disabled .comp-multiselect-dropdown .input-link:focus .selected-list {
  margin: 1px 0;
  border-width: 1px;
  border-color: #C7D0D8;
}
.comp-multiselect-dropdown-container.disabled .comp-multiselect-dropdown .selected-list {
  border-color: #C7D0D8;
}
.comp-multiselect-dropdown-container.disabled .comp-multiselect-dropdown .selected-list.open {
  margin: 1px 0;
  border-width: 1px;
  border-color: #C7D0D8;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown a, .comp-multiselect-dropdown-container .comp-multiselect-dropdown a:visited {
  text-decoration: none;
  color: #000;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .input-link:focus {
  box-shadow: none;
  outline: none;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .input-link:focus .selected-list {
  margin: 0;
  border-width: 2px;
  border-color: #0E4264;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .selected-list {
  width: 100%;
  list-style-type: none;
  min-height: 44px;
  box-sizing: border-box;
  border-radius: 1px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #63798D;
  padding: 6px 32px 7px 12px;
  margin: 1px 0;
  background-image: url("images/dropdown.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .selected-list.open {
  margin: 0;
  border-width: 2px;
  border-color: #0E4264;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .selected-list .item {
  font-family: Open Sans, sans-serif;
  float: left;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .selected-list .item > div {
  display: flex;
  justify-content: space-around;
  background-color: #E3E7EB;
  border-radius: 12px;
  padding: 2px 0 2px 8px;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .selected-list .item > div .closeButton {
  height: 24px;
  width: 24px;
  margin-left: auto;
  margin-right: 0;
  box-sizing: border-box;
  background-image: url("images/close.svg");
  background-repeat: no-repeat, repeat;
  background-position: left 2px top 2px, 0 0;
  background-size: 18px auto, 100%;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu {
  margin: 0 0 0 0;
  /*position: absolute;
  top: 10px;
  left: 0;*/
  width: 100%;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu.closed {
  display: none;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu.open {
  display: block;
  position: absolute;
  z-index: 2;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu .menu-list {
  width: 100%;
  list-style-type: none;
  padding: 5px 0;
  margin-top: -2px;
  background-color: #ffffff;
  border: 1px solid #63798D;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu .menu-list .toolTip-click {
  display: flex;
  width: 100%;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu .menu-list .tooltip-icon-container {
  align-self: flex-end;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu .menu-list .item {
  font-family: Open Sans, sans-serif;
  height: 50px;
  box-sizing: border-box;
  line-height: 38px;
  display: flex;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu .menu-list .item.selected a, .comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu .menu-list .item.selected a:visited {
  background-color: #0E4264;
  color: #ffffff;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu .menu-list .item a, .comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu .menu-list .item a:visited {
  width: 100%;
  height: 100%;
  display: block;
  padding-left: 9px;
}
.comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu .menu-list .item a:hover, .comp-multiselect-dropdown-container .comp-multiselect-dropdown .dropdown-menu .menu-list .item a:visited:hover {
  background-color: #69899C;
  color: #ffffff;
}
.comp-multiselect-dropdown-container .searchOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.comp-numeric-up-down-field-container {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-bottom: 24px;
}
.comp-numeric-up-down-field-container .label {
  font-family: Open Sans, sans-serif;
}
.comp-numeric-up-down-field-container .fieldContainer {
  border: 1px solid #63798D;
  border-radius: 1px;
}
.comp-numeric-up-down-field-container .fieldContainer .MuiInputBase-root {
  width: calc(100% - 44px - 44px);
}
.comp-numeric-up-down-field-container .fieldContainer .MuiInputBase-root input,
.comp-numeric-up-down-field-container .fieldContainer .MuiInputBase-root textarea {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  padding: 10px 12px;
  margin: 1px 0;
}
.comp-numeric-up-down-field-container .fieldContainer .MuiInputBase-root input[disabled],
.comp-numeric-up-down-field-container .fieldContainer .MuiInputBase-root textarea[disabled] {
  border-color: #C7D0D8;
  color: #000;
}
.comp-numeric-up-down-field-container .fieldContainer .MuiInputBase-root input:focus,
.comp-numeric-up-down-field-container .fieldContainer .MuiInputBase-root textarea:focus {
  margin: 0;
}
.comp-numeric-up-down-field-container .button {
  height: 42px;
  width: 44px;
  min-width: 44px;
  background-color: #ffffff;
  border-radius: 0;
}
.comp-numeric-up-down-field-container .button[disabled] {
  border-color: #C7D0D8;
  color: #000;
}
.comp-numeric-up-down-field-container .button.minusButton {
  border-left: 1px solid #63798D;
  border-right: 1px solid #63798D;
}
.comp-numeric-up-down-field-container .button.plusButton {
  border-left: 1px solid #63798D;
}
.comp-numeric-up-down-field-container .button .MuiButton-label {
  width: 44px;
}
.comp-numeric-up-down-field-container.focused .fieldContainer {
  border-width: 2px;
  border-color: #0E4264;
}
.comp-numeric-up-down-field-container.focused .fieldContainer button {
  border-color: #0E4264;
}
.comp-numeric-up-down-field-container.error .fieldContainer {
  border-color: #993366;
}
.comp-numeric-up-down-field-container.error .fieldContainer div > input,
.comp-numeric-up-down-field-container.error .fieldContainer div > textarea {
  border-color: #993366;
}
.comp-numeric-up-down-field-container.error .fieldContainer div > input:focus,
.comp-numeric-up-down-field-container.error .fieldContainer div > textarea:focus {
  border-color: #993366;
}
.comp-numeric-up-down-field-container.error .fieldContainer button {
  border-color: #993366;
}

.show-part-selector {
  margin: 0 0 24px 0;
  width: 100%;
}
.show-part-selector .label-container {
  padding-bottom: 0;
}
.show-part-selector .label-container label {
  color: #0E4264;
}
.show-part-selector .button-container-box {
  border: 2px solid #7DC6F0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.show-part-selector .button-container-box .button-container {
  margin-bottom: 0;
  flex-grow: 1;
}
.show-part-selector .button-container-box .button-container .button {
  box-sizing: border-box;
  height: 44px;
  width: 100%;
  color: #0E4264;
  text-transform: none;
}
.show-part-selector .button-container-box .button-container.active {
  background-color: #0E4264;
  border: 1px solid #ffffff;
}
.show-part-selector .button-container-box .button-container.active .button {
  width: 100%;
  color: #ffffff;
  text-transform: none;
}

.partition-header {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #000;
}

.comp-radio-button-group .MuiRadio-root {
  color: #63798D;
}
.comp-radio-button-group .MuiRadio-root.Mui-checked {
  color: #0E4264;
}
.comp-radio-button-group .MuiRadio-root .MuiSvgIcon-root {
  font-size: 30px;
}
.comp-radio-button-group .MuiRadio-root.Mui-disabled .MuiSvgIcon-root {
  color: #9FB3C1;
}
.comp-radio-button-group .MuiFormControlLabel-label {
  color: #000;
  font-family: Open Sans, sans-serif;
}
.comp-radio-button-group .MuiFormControlLabel-label.Mui-disabled {
  color: #000;
}
.comp-radio-button-group .MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.comp-radio-button-group.error .MuiSvgIcon-root {
  color: #993366;
}
.comp-radio-button-group.error .MuiIconButton-colorSecondary:hover {
  background-color: rgba(245, 0, 87, 0.04);
}

.large-comp-radio-button-group .MuiRadio-root {
  color: #63798D;
}
.large-comp-radio-button-group .MuiRadio-root.Mui-checked {
  color: #0E4264;
}
.large-comp-radio-button-group .MuiRadio-root .MuiSvgIcon-root {
  height: 50px;
  width: 50px;
  font-size: 30px;
}
.large-comp-radio-button-group .MuiRadio-root.Mui-disabled .MuiSvgIcon-root {
  color: #9FB3C1;
}
.large-comp-radio-button-group .MuiFormControlLabel-label {
  font-size: 20px;
  color: #000;
  font-family: Open Sans, sans-serif;
}
.large-comp-radio-button-group .MuiFormControlLabel-label.Mui-disabled {
  color: #000;
}
.large-comp-radio-button-group .MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.large-comp-radio-button-group.error .MuiSvgIcon-root {
  color: #993366;
}
.large-comp-radio-button-group.error .MuiIconButton-colorSecondary:hover {
  background-color: rgba(245, 0, 87, 0.04);
}

.searchOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.search-input .search-input-input {
  border: 1px solid #63798D;
  border-right: 0;
  width: calc(100% - 43px);
  height: 44px;
  padding-left: 16px;
  margin-top: 1px;
}
.search-input .search-input-input > input {
  font-family: Open Sans, sans-serif;
  height: 44px;
}
.search-input .search-input-button {
  border: 1px solid #63798D;
  width: 43px;
  height: 44px;
  min-width: 43px;
  margin-top: 1px;
  padding: 0;
  border-radius: unset;
  float: right;
}
.search-input .search-list-container {
  position: absolute;
  z-index: 1;
  background: white;
  border: 1px solid #63798D;
  padding: 9px 16px 5px 18px;
}
.search-input .search-list-container > div > span {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
}
.search-input .search-list-container.selected {
  background-color: #0E4264;
  color: #ffffff;
}
.search-input .selected-list {
  width: 100%;
  list-style-type: none;
  box-sizing: border-box;
  position: relative;
  padding: 6px 12px 7px 12px;
  margin: 0;
}
.search-input .selected-list .item {
  font-family: Open Sans, sans-serif;
  float: left;
}
.search-input .selected-list .item > div {
  display: flex;
  justify-content: space-around;
  background-color: #E3E7EB;
  border-radius: 12px;
  padding: 2px 0 2px 8px;
}
.search-input .selected-list .item > div .closeButton {
  height: 24px;
  width: 24px;
  margin-left: auto;
  margin-right: 0;
  box-sizing: border-box;
  background-image: url("images/close.svg");
  background-repeat: no-repeat, repeat;
  background-position: left 2px top 2px, 0 0;
  background-size: 18px auto, 100%;
}
.search-input.error .search-input-input {
  border-color: #993366;
}
.search-input.error .search-input-button {
  border-color: #993366;
}
.search-input.disabled .search-input-input {
  display: none;
}
.search-input.disabled .search-input-button {
  display: none;
}
.search-input.disabled .selected-list {
  width: 100%;
  list-style-type: none;
  min-height: 44px;
  box-sizing: border-box;
  border-radius: 1px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #C7D0D8;
  margin: 1px 0;
}
.search-input.disabled .selected-list .item {
  font-family: Open Sans, sans-serif;
  float: left;
}
.search-input.disabled .selected-list .item > div {
  display: flex;
  justify-content: space-around;
  background-color: #E3E7EB;
  border-radius: 12px;
  padding: 2px 8px 2px 8px;
}
.search-input.disabled .selected-list .item > div .closeButton {
  display: none;
}

.search-list-wrapper .search-list {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  border: 1px solid #63798D;
}
.search-list-wrapper .search-list .search-list-title {
  background-color: #63798D;
  line-height: 1.25rem;
  padding: 12px 13px 7px 20px;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}
.search-list-wrapper .search-list .search-list-input {
  border-top: 1px solid #63798D;
  border-bottom: 1px solid #63798D;
  width: calc(100% - 43px);
  height: 45px;
  padding-left: 16px;
}
.search-list-wrapper .search-list .search-list-input > input {
  font-family: Open Sans, sans-serif;
  height: 45px;
}
.search-list-wrapper .search-list .search-list-button {
  border: 1px solid #63798D;
  border-right: none;
  width: 43px;
  height: 45px;
  min-width: 43px;
  padding: 0;
  border-radius: unset;
  float: right;
}
.search-list-wrapper .search-list .search-list-container {
  padding-top: 0;
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.search-list-wrapper .search-list .search-list-container .search-list-item {
  padding: 9px 16px 5px 18px;
  border-top: 1px solid #C7D0D8;
  min-height: 44px;
}
.search-list-wrapper .search-list .search-list-container .search-list-item > div > span {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
}
.search-list-wrapper .search-list .search-list-container .search-list-item.selected {
  background-color: #0E4264;
  color: #ffffff;
}
.search-list-wrapper .search-list .search-list-container::-webkit-scrollbar {
  width: 32px;
}
.search-list-wrapper .search-list .search-list-container::-webkit-scrollbar-track {
  background: #ffffff;
}
.search-list-wrapper .search-list .search-list-container::-webkit-scrollbar-track-piece {
  background: #ffffff;
}
.search-list-wrapper .search-list .search-list-container::-webkit-scrollbar-thumb {
  background: #C7D0D8;
  border: 9px solid #ffffff;
}
.search-list-wrapper .search-list .search-list-container::-webkit-scrollbar-thumb:hover {
  background: #63798D;
}
.search-list-wrapper .search-list.disabled .search-list-input {
  display: none;
}
.search-list-wrapper .search-list.disabled .search-list-button {
  display: none;
}
.search-list-wrapper .search-list.disabled .search-list-container .search-list-item {
  border-color: #C7D0D8;
  cursor: default;
}
.search-list-wrapper .search-list.disabled .search-list-container .search-list-item.selected {
  opacity: 1;
  background-color: #ffffff;
  color: #000;
}
.search-list-wrapper.error .search-list {
  border-color: #993366;
}
.search-list-wrapper.error .search-list .search-list-input {
  border-color: #993366;
}
.search-list-wrapper.error .search-list .search-list-button {
  border-color: #993366;
}
.search-list-wrapper.error .search-list .search-list-container .search-list-item {
  border-color: #993366;
}

.CompTable-paginator {
  flex-shrink: 0;
  margin-left: 8px;
}

.comp-text-field-container {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-bottom: 24px;
}
.comp-text-field-container .label {
  font-family: Open Sans, sans-serif;
}
.comp-text-field-container div {
  width: 100%;
}
.comp-text-field-container div input,
.comp-text-field-container div textarea {
  width: 100%;
  border-radius: 1px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #63798D;
  padding: 10px 12px;
  margin: 1px 0;
}
.comp-text-field-container div input[disabled],
.comp-text-field-container div textarea[disabled] {
  border-color: #C7D0D8;
  color: #000;
}
.comp-text-field-container div input:focus,
.comp-text-field-container div textarea:focus {
  margin: 0;
  border-width: 2px;
  border-color: #0E4264;
}
.comp-text-field-container.error div > input,
.comp-text-field-container.error div > textarea {
  border-color: #993366;
}
.comp-text-field-container.error div > input:focus,
.comp-text-field-container.error div > textarea:focus {
  border-color: #993366;
}

.comp-timepicker-container {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-bottom: 24px;
}
.comp-timepicker-container .MuiTextField-root {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}
.comp-timepicker-container .MuiTextField-root .MuiInput-formControl {
  border-radius: 1px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #63798D;
  padding: 10px 12px;
  margin: 1px 0;
}
.comp-timepicker-container .MuiTextField-root .MuiInput-formControl:before {
  border: 0;
}
.comp-timepicker-container .MuiTextField-root .MuiInput-formControl:after {
  border: 0;
}
.comp-timepicker-container .MuiTextField-root .MuiInput-formControl.Mui-focused {
  margin: 0;
  border-width: 2px;
  border-color: #0E4264;
}
.comp-timepicker-container .MuiTextField-root .MuiInput-formControl .MuiInput-input {
  width: 100%;
  padding: 0;
}
.comp-timepicker-container .MuiTextField-root .MuiInput-formControl .MuiInput-input[disabled] {
  color: #000;
}
.comp-timepicker-container .MuiTextField-root .MuiInput-formControl .MuiInputAdornment-root .MuiIconButton-root {
  color: #0E4264;
}
.comp-timepicker-container .MuiTextField-root .MuiFormHelperText-root {
  font-family: Open Sans, sans-serif;
  display: inherit;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #993366;
  margin-top: 6px;
}
.comp-timepicker-container.disabled .MuiTextField-root .MuiInput-formControl {
  border-color: #C7D0D8;
}
.comp-timepicker-container.error .MuiTextField-root > .MuiInput-formControl {
  border-color: #993366;
}
.comp-timepicker-container.error .MuiTextField-root > .MuiInput-formControl .MuiInputAdornment-root .MuiIconButton-root {
  color: #993366;
}
.comp-timepicker-container.error .MuiTextField-root > .MuiInput-formControl:focus {
  border-color: #993366;
}

.MuiPickersModal-dialog .MuiPickersDay-daySelected {
  background-color: #0E4264;
}
.MuiPickersModal-dialog .MuiDialogActions-root button {
  color: #0E4264;
}

.tooltip-icon-container .popoverOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.tooltip-icon-popover .MuiPopover-paper {
  background-color: #FFD9E3;
  font-family: Open Sans, sans-serif;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  overflow: initial;
  max-width: 300px;
}
.tooltip-icon-popover .MuiPopover-paper::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 12px solid transparent;
}
.tooltip-icon-popover .MuiPopover-paper.top::after {
  top: 100%;
  left: calc(50% - 12px);
  border-bottom: none;
  border-top-color: #FFD9E3;
  filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.2));
}
.tooltip-icon-popover .MuiPopover-paper.left::after {
  left: 100%;
  top: calc(50% - 12px);
  border-right: none;
  border-left-color: #FFD9E3;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}
.tooltip-icon-popover .MuiPopover-paper.right::after {
  right: 100%;
  top: calc(50% - 12px);
  border-left: none;
  border-right-color: #FFD9E3;
}

/** MODULES **/
.loginRoot {
  height: 100vh;
  padding-top: 0;
}
.loginRoot .loginBackgroundImage {
  background-image: url("images/PictLogin_72ppi.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.loginRoot .feedback {
  padding-top: 8px;
}
.loginRoot .langChange {
  position: absolute;
  top: 8px;
  right: 16px;
}
.loginRoot .high {
  background-image: url("images/PictLogin_72ppi.png");
  height: 100vh;
}
.loginRoot .mid {
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
}
.loginRoot .align-right {
  text-align: end;
}
.loginRoot .title {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #0E4264;
  text-align: center;
  margin: 0 0 32px 0;
}
.loginRoot .paper {
  padding-top: 10%;
  padding-bottom: 10%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.loginRoot .paper .paperHeader .appLogo {
  max-width: 100%;
}
.loginRoot .paper .paperContent {
  height: 60%;
}
.loginRoot .paper .paperContent .title {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #0E4264;
  text-align: center;
  margin: 0 0 32px 0;
}
.loginRoot .paper .paperContent .MuiAlert-root {
  width: 100%;
}
.loginRoot .paper .paperContent .form {
  width: 100%;
  max-width: 450px;
  margin: 0 0 32px 0;
}
.loginRoot .paper .paperContent .form .submit {
  margin: 1rem 0 0.5rem 0;
}
.loginRoot .paper .paperContent .form .forgotPass {
  color: #0E4264;
}
.loginRoot .paper .paperFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 450px;
}
.loginRoot .paper .paperFooter p {
  align-content: center;
}
.loginRoot .paper .paperFooter img {
  max-width: 185px;
}

@media all and (max-width: 1280px) {
  .loginRoot .paper .paperFooter {
    flex-direction: column;
    align-items: center;
  }
}
.CompContentIdentifierTable-wrapper .CompContentIdentifierTable-mainTitle {
  background-color: #63798D;
  color: #ffffff;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  font-style: normal;
  font-weight: bold;
}
.CompContentIdentifierTable-wrapper .CompContentIdentifierTable-container .CompContentIdentifierTable-table .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head {
  background-color: #E3E7EB;
  color: #0E4264;
}
.CompContentIdentifierTable-wrapper .CompContentIdentifierTable-container .CompContentIdentifierTable-table .MuiTableBody-root .MuiTableRow-root.Mui-selected, .CompContentIdentifierTable-wrapper .CompContentIdentifierTable-container .CompContentIdentifierTable-table .MuiTableBody-root .MuiTableRow-root.Mui-selected:hover {
  background-color: #0E4264;
}
.CompContentIdentifierTable-wrapper .CompContentIdentifierTable-container .CompContentIdentifierTable-table .MuiTableBody-root .MuiTableRow-root.Mui-selected .MuiTableCell-body, .CompContentIdentifierTable-wrapper .CompContentIdentifierTable-container .CompContentIdentifierTable-table .MuiTableBody-root .MuiTableRow-root.Mui-selected:hover .MuiTableCell-body {
  color: #ffffff;
}
.CompContentIdentifierTable-wrapper .CompContentIdentifierTable-container .CompContentIdentifierTable-table .MuiTableFooter-root .MuiTableRow-footer .CompContentIdentifierTable-paginator {
  flex-shrink: 0;
  margin-left: 8px;
}

.contentIdentifier-container .listComponent {
  min-height: 255px;
}

.feedbackRoot {
  height: 95vh;
  padding-top: 0;
}
.feedbackRoot .label-size {
  font-size: 24px;
  font-weight: bold;
}
.feedbackRoot .feedback {
  padding-top: 8px;
}
.feedbackRoot .langChange {
  position: absolute;
  top: 8px;
  right: 16px;
}
.feedbackRoot .high {
  background-image: url("images/PictLogin_72ppi.png");
  height: 100vh;
}
.feedbackRoot .mid {
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
}
.feedbackRoot .align-right {
  text-align: end;
}
.feedbackRoot .title {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 20px;
  color: #0E4264;
  text-align: center;
  margin: 0 0 32px 0;
}
.feedbackRoot .green {
  color: #137913;
}
.feedbackRoot .red {
  color: #993366;
}
.feedbackRoot .paper {
  padding-top: 10%;
  padding-bottom: 10%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.feedbackRoot .paper .paperHeader .appLogo {
  max-width: 100%;
}
.feedbackRoot .paper .paperContent {
  height: 60%;
}
.feedbackRoot .paper .paperContent .title {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #0E4264;
  text-align: center;
  margin: 0 0 32px 0;
}
.feedbackRoot .paper .paperContent .MuiAlert-root {
  width: 100%;
}
.feedbackRoot .paper .paperContent .form {
  width: 100%;
  max-width: 450px;
  margin: 0 0 32px 0;
}
.feedbackRoot .paper .paperContent .form .submit {
  margin: 1rem 0 0.5rem 0;
}
.feedbackRoot .paper .paperContent .form .forgotPass {
  color: #0E4264;
}
.feedbackRoot .paper .paperFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 450px;
}
.feedbackRoot .paper .paperFooter p {
  align-content: center;
}
.feedbackRoot .paper .paperFooter img {
  max-width: 185px;
}

@media all and (max-width: 1280px) {
  .feedbackRoot .label-size {
    font-size: 18px;
    font-weight: bold;
  }
  .feedbackRoot .small-title {
    font-size: 20px;
  }
  .feedbackRoot .buttonText {
    font-size: 13px;
  }
  .feedbackRoot .feedbackmiddle {
    min-width: 100%;
  }
  .feedbackRoot .paper .paperFooter {
    flex-direction: column;
    align-items: center;
  }
}
.option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MuiPopover-paper {
  white-space: break-spaces;
  top: 370px;
}

.comp-accordion-details {
  width: initial;
}

.CompFunctionCards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}
.CompFunctionCards-container .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.CompFunctionCards-container .CompFunctionCards-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid #63798D;
  margin: 3px 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}
.CompFunctionCards-container .CompFunctionCards-row .cell-image {
  width: 44px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-image img {
  height: 34px;
  width: auto;
  vertical-align: middle;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 10px;
  padding: 10px 0;
  cursor: pointer;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-content .information {
  width: 40%;
  min-width: 30%;
  display: flex;
  justify-content: left;
  flex-direction: column;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-content .information .title {
  font-weight: bold;
  padding-right: 10px;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-content .organizers {
  flex: 1;
  display: flex;
  align-items: center;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-content .participants {
  justify-content: center;
  flex: 1 1;
  display: flex;
  align-items: center;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: calc(180px + 100px);
  margin: 10px 10px 10px 0;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-right .dates {
  width: 180px;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .CompFunctionCards-container .CompFunctionCards-row .cell-right {
    width: auto;
  }
}
.CompFunctionCards-container .CompFunctionCards-row .cell-right .actions {
  width: 100px;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-right .actions .editButton {
  border: none;
  background-color: inherit;
  color: #255DD0;
  line-height: 12px;
  height: 24px;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-right .actions .editButton:hover {
  cursor: pointer;
  text-decoration: underline;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-right .actions .editButton:active, .CompFunctionCards-container .CompFunctionCards-row .cell-right .actions .editButton:focus {
  color: #0E4264;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-right .actions .editButton .editButton-image {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
}
.CompFunctionCards-container .CompFunctionCards-row .cell-right .actions .editButton .editButton-text {
  display: inline-block;
  vertical-align: middle;
}
.CompFunctionCards-container .CompFunctionCards-header {
  height: inherit;
  border: none;
  margin: 0;
  box-shadow: none;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #0E4264;
}
.CompFunctionCards-container .CompFunctionCards-header .cell-image {
  cursor: default;
}
.CompFunctionCards-container .CompFunctionCards-header .cell-content {
  padding: 0;
  cursor: default;
}
.CompFunctionCards-container .CompFunctionCards-header .cell-content .organizers {
  height: inherit;
  line-height: inherit;
}
.CompFunctionCards-container .CompFunctionCards-header .cell-right {
  margin: 0 10px 0 0;
}
.CompFunctionCards-container .CompFunctionCards-header .cell-right .dates {
  width: 100%;
  cursor: default;
}
.CompFunctionCards-container .CompFunctionCards-footer {
  height: 40px;
  width: 100%;
}
.CompFunctionCards-container .CompFunctionCards-footer table {
  width: 100%;
}
.CompFunctionCards-container .CompFunctionCards-footer table .MuiTablePagination-root {
  min-height: 40px;
  width: 100%;
  display: block;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiTablePagination-spacer {
  flex: 1 1 100%;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiTablePagination-caption {
  flex-shrink: 0;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiTablePagination-selectRoot {
  margin-left: 8px;
  margin-right: 32px;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiTablePagination-input {
  color: inherit;
  font-size: inherit;
  flex-shrink: 0;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiTablePagination-select {
  text-align: right;
  padding-left: 8px;
  padding-right: 24px;
  text-align-last: right;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiSelect-select {
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiInputBase-root {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 0;
  position: absolute;
  pointer-events: none;
}
.CompFunctionCards-container .CompFunctionCards-footer .MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.event-hours-timepicker-container {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-bottom: 24px;
}
.event-hours-timepicker-container .MuiTextField-root {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}
.event-hours-timepicker-container .MuiTextField-root .MuiInput-formControl {
  border-radius: 1px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #63798D;
  padding: 10px 12px;
  margin: 1px 0;
}
.event-hours-timepicker-container .MuiTextField-root .MuiInput-formControl:before {
  border: 0;
}
.event-hours-timepicker-container .MuiTextField-root .MuiInput-formControl:after {
  border: 0;
}
.event-hours-timepicker-container .MuiTextField-root .MuiInput-formControl.Mui-focused {
  margin: 0;
  border-width: 2px;
  border-color: #0E4264;
}
.event-hours-timepicker-container .MuiTextField-root .MuiInput-formControl .MuiInput-input {
  width: 100%;
  padding: 0;
}
.event-hours-timepicker-container .MuiTextField-root .MuiInput-formControl .MuiInput-input[disabled] {
  color: #000;
}
.event-hours-timepicker-container .MuiTextField-root .MuiInput-formControl .MuiInputAdornment-root .MuiIconButton-root {
  color: #0E4264;
}
.event-hours-timepicker-container .MuiTextField-root .MuiFormHelperText-root {
  font-family: Open Sans, sans-serif;
  display: inherit;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #993366;
  margin-top: 6px;
}
.event-hours-timepicker-container .fields {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.event-hours-timepicker-container.disabled .MuiTextField-root .MuiInput-formControl {
  border-color: #C7D0D8;
}
.event-hours-timepicker-container.error .MuiTextField-root > .MuiInput-formControl {
  border-color: #993366;
}
.event-hours-timepicker-container.error .MuiTextField-root > .MuiInput-formControl .MuiInputAdornment-root .MuiIconButton-root {
  color: #993366;
}
.event-hours-timepicker-container.error .MuiTextField-root > .MuiInput-formControl:focus {
  border-color: #993366;
}

@media only screen and (max-width: 630px) {
  .CompFunctionCards-container .CompFunctionCards-row {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 20px;
  }
  .CompFunctionCards-container .CompFunctionCards-row .cell-content {
    margin: 0;
  }
  .CompFunctionCards-container .CompFunctionCards-row .cell-right {
    flex-wrap: nowrap;
  }
  .CompFunctionCards-container .CompFunctionCards-row .cell-right .dates {
    width: 100px;
  }
}
@media only screen and (max-width: 535px) {
  .CompFunctionCards-container .CompFunctionCards-row {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 20px;
  }
  .CompFunctionCards-container .CompFunctionCards-row .cell-content {
    margin: 0;
  }
  .CompFunctionCards-container .CompFunctionCards-row .cell-right {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .CompFunctionCards-container .CompFunctionCards-row .cell-right .dates {
    width: 100px;
  }
}
@media all and (max-width: 1280px) {
  .CompFunctionCards-container .CompFunctionCards-row {
    height: inherit;
  }
  .CompFunctionCards-container .CompFunctionCards-row .cell-content {
    height: 8em;
    flex-direction: column;
  }
  .CompFunctionCards-container .CompFunctionCards-row .cell-content .participants {
    justify-content: flex-start;
  }
  .CompFunctionCards-container .CompFunctionCards-row .cell-content .information {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    place-content: space-evenly;
  }
  .CompFunctionCards-container .CompFunctionCards-row .cell-right {
    width: initial;
    min-width: initial;
  }
  .CompFunctionCards-container .CompFunctionCards-header {
    display: none;
  }
}
.reminder-button-icon-img {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.listLocationsComponent {
  min-height: 430px;
}
.listLocationsComponent.loading {
  /* Safari */
}
.listLocationsComponent.loading::after {
  content: "";
  position: absolute;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.location-container > .MuiGrid-container > .MuiGrid-item {
  position: relative;
}
.location-container > .MuiGrid-container > .MuiGrid-item .tooltip-icon-container {
  position: absolute;
  top: -29px;
  float: right;
  right: 32px;
}

.listMunicipalitiesComponent {
  min-height: 486px;
}
.listMunicipalitiesComponent.loading {
  /* Safari */
}
.listMunicipalitiesComponent.loading::after {
  content: "";
  position: absolute;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.listMunicipalitiesComponent .search-list-container {
  max-height: 400px;
}
.listMunicipalitiesComponent .search-list-container .search-list-item.DRAFT {
  padding-left: 8px;
  border-left: 10px solid #FFE561;
}

.listOrganizationsComponent {
  min-height: 486px;
}
.listOrganizationsComponent .list-tree-container {
  max-height: 445px;
}

.municipality-container > .MuiGrid-container > .MuiGrid-item {
  position: relative;
}
.municipality-container > .MuiGrid-container > .MuiGrid-item .tooltip-icon-container {
  position: absolute;
  top: -29px;
  float: right;
  right: 8px;
}

.reports .reportMapContainer {
  margin-left: -40px;
  margin-right: 40px;
}
.reports .listComponent {
  min-height: 430px;
}
.reports .listComponent .search-list-container {
  max-height: 372px;
}
.reports .wrapperDoc {
  display: flex;
}
.reports .sliderContainer {
  padding: 0 20px;
}
.reports .sliderContainer .label {
  margin-left: -20px;
}
.reports .sliderContainer .MuiSlider-root .MuiSlider-thumb {
  width: 24px;
  height: 24px;
  margin-top: -11px;
  margin-left: -11px;
  background-color: #0E4264;
}
.reports .sliderContainer .MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel > span {
  background-color: #0E4264;
  margin-left: 12px;
}
.reports .sliderContainer .MuiSlider-root .MuiSlider-mark {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
  border-radius: 50%;
  border: 1px solid #0E4264;
  background-color: #ffffff;
}
.reports .sliderContainer .MuiSlider-root .MuiSlider-rail {
  height: 24px;
  margin-top: -12px;
  margin-left: 0;
  border-radius: 1px;
  background-color: #7DC6F0;
}
.reports .balloon-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 23px;
}
.reports .balloon-row .balloon-notification-container {
  flex: 0 1;
}
.reports .balloon-row .balloon-notification-container .balloon-notification-top .title {
  min-width: 130px;
  justify-content: center;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
}
.reports .balloon-row .balloon-notification-container .balloon-notification-top .title::after {
  left: calc(50% - 12px);
}
.reports .balloon-row .balloon-notification-container .content {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  text-align: center;
  text-transform: uppercase;
  margin-top: 3px;
  justify-content: center;
  min-width: -webkit-fill-available;
  max-width: fit-content;
  width: auto;
}
.reports .column-box-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
}
.reports .column-box-container .column-box-title {
  background: #63798D;
  height: 65px;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}
.reports .column-box-container .column-box-content {
  background: rgba(115, 137, 157, 0.1);
  padding: 32px;
}
.reports.dataQueryPage .comp-radio-button-group {
  flex-direction: row;
  justify-content: space-between;
}

@media all and (max-width: 1280px) {
  .reports .reportMapContainer {
    margin-left: initial;
    margin-right: initial;
  }
}
.requests-cards .icon-item {
  padding: 0 7px 0 0;
  width: 84px;
}
.requests-cards .comp-cards-head .comp-cards-head-row th {
  padding: 0;
}
.requests-cards .comp-cards-row td {
  padding: 0;
}
.requests-cards .comp-cards-row .icon-item {
  height: 66px;
}
.requests-cards .comp-cards-row p {
  margin: 0;
}
.requests-cards .comp-cards-row .action-icon-img {
  max-width: 84px;
  min-height: 66px;
}

.CompSystemLogTable-wrapper .CompSystemLogTable-mainTitle {
  background-color: #63798D;
  color: #ffffff;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  font-style: normal;
  font-weight: bold;
}
@media all and (max-width: 500px) {
  .CompSystemLogTable-wrapper .CompSystemLogTable-table {
    display: flex;
  }
  .CompSystemLogTable-wrapper .CompSystemLogTable-table .MuiTableHead-root {
    display: flex;
    place-items: flex-end;
  }
  .CompSystemLogTable-wrapper .CompSystemLogTable-table .MuiTableHead-root .MuiTableRow-root {
    display: flex;
    flex-direction: column;
  }
  .CompSystemLogTable-wrapper .CompSystemLogTable-table .MuiTableBody-root {
    display: flex;
  }
  .CompSystemLogTable-wrapper .CompSystemLogTable-table .MuiTableBody-root .MuiTableRow-root {
    display: flex;
    flex-direction: column;
  }
}
.CompSystemLogTable-wrapper .CompSystemLogTable-container .CompSystemLogTable-table .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head {
  background-color: #E3E7EB;
  color: #0E4264;
}
.CompSystemLogTable-wrapper .CompSystemLogTable-container .CompSystemLogTable-table .MuiTableBody-root .MuiTableRow-root.Mui-selected, .CompSystemLogTable-wrapper .CompSystemLogTable-container .CompSystemLogTable-table .MuiTableBody-root .MuiTableRow-root.Mui-selected:hover {
  background-color: #0E4264;
}
.CompSystemLogTable-wrapper .CompSystemLogTable-container .CompSystemLogTable-table .MuiTableBody-root .MuiTableRow-root.Mui-selected .MuiTableCell-body, .CompSystemLogTable-wrapper .CompSystemLogTable-container .CompSystemLogTable-table .MuiTableBody-root .MuiTableRow-root.Mui-selected:hover .MuiTableCell-body {
  color: #ffffff;
}
.CompSystemLogTable-wrapper .CompSystemLogTable-container .CompSystemLogTable-table .MuiTableFooter-root .MuiTableRow-footer .CompSystemLogTable-paginator {
  flex-shrink: 0;
  margin-left: 8px;
}

.systemLogFilterModal {
  display: flex;
  flex-direction: column;
}
.systemLogFilterModal .title {
  margin-bottom: 13px;
}
.systemLogFilterModal .modalButtons {
  align-self: flex-end;
}

.search_wrapper {
  display: flex;
  flex-direction: row;
}

.CompUserCards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}
.CompUserCards-container .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.CompUserCards-container .CompUserCards-row {
  width: 100%;
  height: 100px;
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid #63798D;
  margin: 3px 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}
.CompUserCards-container .CompUserCards-row .cell-image {
  width: 84px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CompUserCards-container .CompUserCards-row .cell-image img {
  vertical-align: middle;
  max-width: 84px;
  max-height: calc(68px - 6px);
  margin-left: 10px;
}
.CompUserCards-container .CompUserCards-row .cell-content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 10px;
  padding: 10px 0;
  cursor: pointer;
}
.CompUserCards-container .CompUserCards-row .cell-content .information {
  width: 40%;
  min-width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.CompUserCards-container .CompUserCards-row .cell-content .information .title {
  font-weight: bold;
}
.CompUserCards-container .CompUserCards-row .cell-content .roles {
  flex: 1 1;
  min-width: 60px;
  display: flex;
  align-items: center;
}
.CompUserCards-container .CompUserCards-row .cell-content .organization {
  flex: 1;
  min-width: 135px;
  display: flex;
  align-items: center;
}
.CompUserCards-container .CompUserCards-row .cell-content .municipality {
  flex: 1;
  min-width: 135px;
  display: flex;
  align-items: center;
}
.CompUserCards-container .CompUserCards-row .cell-right {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  align-self: center;
  margin: 10px 10px 10px 0;
}
.CompUserCards-container .CompUserCards-row .cell-right .dates {
  width: 100px;
  cursor: pointer;
}
.CompUserCards-container .CompUserCards-row .cell-right .actions {
  width: 100px;
}
.CompUserCards-container .CompUserCards-row .cell-right .actions .editButton {
  border: none;
  background-color: inherit;
  color: #255DD0;
  line-height: 12px;
  height: 24px;
}
.CompUserCards-container .CompUserCards-row .cell-right .actions .editButton:hover {
  cursor: pointer;
  text-decoration: underline;
}
.CompUserCards-container .CompUserCards-row .cell-right .actions .editButton:active, .CompUserCards-container .CompUserCards-row .cell-right .actions .editButton:focus {
  color: #0E4264;
}
.CompUserCards-container .CompUserCards-row .cell-right .actions .editButton .editButton-image {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
}
.CompUserCards-container .CompUserCards-row .cell-right .actions .editButton .editButton-text {
  display: inline-block;
  vertical-align: middle;
}
.CompUserCards-container .CompUserCards-header {
  height: inherit;
  border: none;
  margin: 0;
  box-shadow: none;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #0E4264;
}
.CompUserCards-container .CompUserCards-header .cell-image {
  cursor: default;
}
.CompUserCards-container .CompUserCards-header .cell-content {
  padding: 0;
  cursor: default;
}
.CompUserCards-container .CompUserCards-header .cell-content .organization {
  height: inherit;
  line-height: inherit;
}
.CompUserCards-container .CompUserCards-header .cell-right {
  margin: 0 10px 0 0;
}
.CompUserCards-container .CompUserCards-header .cell-right .dates {
  cursor: default;
}
.CompUserCards-container .CompUserCards-footer {
  width: 100%;
}
.CompUserCards-container .CompUserCards-footer table {
  width: 100%;
}
.CompUserCards-container .CompUserCards-footer table .MuiTablePagination-root {
  width: 100%;
  display: block;
}
.CompUserCards-container .CompUserCards-footer .MuiTablePagination-spacer {
  flex: 1 1 100%;
}
.CompUserCards-container .CompUserCards-footer .MuiTablePagination-caption {
  flex-shrink: 0;
}
.CompUserCards-container .CompUserCards-footer .MuiTablePagination-selectRoot {
  margin-left: 8px;
  margin-right: 32px;
}
.CompUserCards-container .CompUserCards-footer .MuiTablePagination-input {
  color: inherit;
  font-size: inherit;
  flex-shrink: 0;
}
.CompUserCards-container .CompUserCards-footer .MuiTablePagination-select {
  text-align: right;
  padding-left: 8px;
  padding-right: 24px;
  text-align-last: right;
}
.CompUserCards-container .CompUserCards-footer .MuiSelect-select {
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.CompUserCards-container .CompUserCards-footer .MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
}
.CompUserCards-container .CompUserCards-footer .MuiInputBase-root {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}
.CompUserCards-container .CompUserCards-footer .MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}
.CompUserCards-container .CompUserCards-footer .MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 0;
  position: absolute;
  pointer-events: none;
}
.CompUserCards-container .CompUserCards-footer .MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

@media all and (max-width: 480px) {
  .CompUserCards-row {
    flex-direction: column;
    padding: 10px;
  }
  .CompUserCards-row .cell-right {
    margin: 0 !important;
    margin-left: 10px !important;
    align-self: normal !important;
    justify-content: space-between !important;
    flex-direction: row !important;
  }
}
@media all and (max-width: 1280px) {
  .search_wrapper {
    flex-direction: column;
  }
  .CompUserCards-container .CompUserCards-row {
    height: inherit;
  }
  .CompUserCards-container .CompUserCards-row .cell-content {
    flex-direction: column;
  }
  .CompUserCards-container .CompUserCards-row .cell-content .information {
    width: 100%;
  }
  .CompUserCards-container .CompUserCards-row .cell-right {
    width: initial;
    min-width: initial;
    align-self: center;
  }
  .CompUserCards-container .CompUserCards-header {
    display: none;
  }
}
.user-cards .icon-item {
  padding: 0 7px 0 0;
  width: 84px;
}
.user-cards .comp-cards-head .comp-cards-head-row th {
  padding: 0;
}
.user-cards .comp-cards-row td {
  padding: 0;
}
.user-cards .comp-cards-row .icon-item {
  height: 54px;
}
.user-cards .comp-cards-row p {
  margin: 0;
}
.user-cards .comp-cards-row .user-icon-img {
  padding-left: 20px;
  max-width: 84px;
  min-height: 54px;
}

/** APPLICATION **/
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/WorkSans-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  src: url("fonts/WorkSans-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  src: url("fonts/WorkSans-Bold.otf") format("opentype");
}
html, body {
  height: auto;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #000;
}

input, select,
.MuiInputBase-input,
.MuiTableCell-root,
.MuiTypography-body1,
.MuiTypography-body2 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

select {
  min-height: 44px;
}

.MuiInputBase-input {
  min-height: 22px;
}

.MuiFormLabel-root {
  font-family: Open Sans, sans-serif;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: #0E4264;
}

.MuiTypography-body1 {
  color: inherit;
}

.MuiButton-root {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.root {
  display: flex;
  background-color: #0E4264;
}

.contentsDiv {
  display: contents;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.bold {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.align-right {
  text-align: end;
}

.mainPageText {
  overflow-wrap: anywhere;
}

.appBar {
  z-index: 1201;
  box-shadow: none;
}

.error-label {
  font-family: Open Sans, sans-serif;
  display: none;
}

.error .error-label {
  display: inherit;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #993366;
  margin-top: 6px;
}

.MuiAlert-root {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 12px;
}

.MuiAlert-standardError {
  color: #0E4264;
  background-color: #FFECEF;
}

.MuiAlert-standardWarning {
  color: #0E4264;
  background-color: #FFFADF;
}

.MuiAlert-standardInfo {
  color: #0E4264;
  background-color: #E8F4FD;
}

.MuiAlert-standardSuccess {
  color: #0E4264;
  background-color: #FCFBFB;
}

.clear {
  clear: both;
}

.large-text {
  font-size: 18px;
}

.label,
.MuiSlider-markLabel {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0E4264;
  margin-bottom: 0;
}

.MuiAppBar-colorPrimary {
  color: #ffffff;
  background-color: #0E4264;
}
.MuiAppBar-colorPrimary .appBarToolbar {
  width: 100%;
  height: 74px;
  background: #0E4264;
  padding-left: 0;
  padding-right: 20px;
}
.MuiAppBar-colorPrimary .appBarToolbar .logo-container {
  padding-left: 0.8rem;
  width: 16.9rem;
  display: inline-block;
}
.MuiAppBar-colorPrimary .appBarToolbar .logo-container .header-logo-normal {
  padding-left: 16px;
  padding-right: 16px;
  display: block;
}
.MuiAppBar-colorPrimary .appBarToolbar a.appBar-link,
.MuiAppBar-colorPrimary .appBarToolbar a.appBar-link:visited {
  color: #ffffff;
  text-decoration: none;
  height: 74px;
  line-height: 74px;
  display: inline-block;
}
.MuiAppBar-colorPrimary .appBarToolbar a.appBar-link:hover,
.MuiAppBar-colorPrimary .appBarToolbar a.appBar-link:visited:hover {
  background-color: #E3E7EB;
  color: #0E4264;
  border-bottom: 10px solid #63798D;
}
.MuiAppBar-colorPrimary .appBarToolbar a.appBar-link:active, .MuiAppBar-colorPrimary .appBarToolbar a.appBar-link:focus,
.MuiAppBar-colorPrimary .appBarToolbar a.appBar-link:visited:active,
.MuiAppBar-colorPrimary .appBarToolbar a.appBar-link:visited:focus {
  background-color: #63798D;
  color: #ffffff;
  border-bottom: 0;
}
.MuiAppBar-colorPrimary .appBarToolbar .breadcrumb-container {
  padding-top: 0;
  text-align: center;
}
.MuiAppBar-colorPrimary .appBarToolbar .breadcrumb-container .breadcrumb {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  color: #ffffff;
  margin: auto;
  padding-left: 11px;
  padding-right: 11px;
  display: inline-block;
  vertical-align: middle;
}
.MuiAppBar-colorPrimary .appBarToolbar .breadcrumb-container .breadcrumb a, .MuiAppBar-colorPrimary .appBarToolbar .breadcrumb-container .breadcrumb a:visited {
  padding-left: 6px;
  padding-right: 6px;
}
.MuiAppBar-colorPrimary .appBarToolbar .language-selector {
  margin-left: 15px;
  margin-right: 15px;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;
  cursor: pointer;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenuButton {
  color: #ffffff;
  background-color: #0E4264;
}
.MuiAppBar-colorPrimary .appBarToolbar .user-icon {
  height: 54px;
}
.MuiAppBar-colorPrimary .appBarToolbar .user-icon img {
  height: 54px;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenu {
  position: absolute;
  top: 0;
  right: 0;
  width: 290px;
  background-color: #ffffff;
  color: #0E4264;
  padding: 0 30px 12px 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  z-index: 1;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-icon {
  position: fixed;
  top: 10px;
  right: 2px;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-icon:hover {
  background-color: initial;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-name {
  margin-bottom: 0;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-menu > li > button,
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-menu > li > a {
  border: 1px solid #ffffff;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-menu > li > button:hover,
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-menu > li > a:hover {
  border: 1px solid #0E4264;
  background-color: initial;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-menu > li > button span,
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-menu > li > a span {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  text-transform: none;
  color: #0E4264;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-menu .MuiSvgIcon-root,
.MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-menu .userMenu-menu-icon-img {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.MuiAppBar-colorPrimary .appBarToolbar .userMenuOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: inherit;
}
.MuiAppBar-colorPrimary .appBarToolbarMobile {
  padding-bottom: 0;
  padding-left: 9px;
  min-height: auto;
  height: 2.9rem;
  border-top: 1px solid #ffffff;
}
.MuiAppBar-colorPrimary .appBarToolbarMobile .logo-container {
  padding-left: 0.8rem;
  width: 16.9rem;
  display: inline-block;
}
.MuiAppBar-colorPrimary .appBarToolbarMobile .logo-container .header-logo-mobile {
  display: block;
}

.container {
  margin-left: 10px;
  width: calc(100% - 16.9rem - 10px);
  padding: 2.8rem 20px 0 20px;
}
.container .bottom-button-container {
  padding-top: 1.5rem;
}

.drawerToolbar {
  min-height: 5.4rem;
}

.drawer-title {
  height: 2.8rem;
  padding-left: 20px;
  padding-right: 16px;
}
.drawer-title span {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 45px;
  color: #ffffff;
}

/** appBarSpacer: theme.mixins.toolbar;**/
.drawer-list {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
}
.drawer-list > div {
  height: 100%;
}

.listItemLink {
  /*height: 4.9rem;*/
  height: 78px;
  border-radius: 0;
  border-top: 0;
  border-bottom: 1px solid;
  border-left: 0;
  border-right: 0;
  color: #0E4264;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}
.listItemLink > div {
  margin-top: 7px;
}
.listItemLink > div > span {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
}
.listItemLink .notification-balloon {
  background-color: #FFE561;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #0E4264;
}

main {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  margin-right: 10px;
  padding-top: 74px;
}
main.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
main.content .container {
  flex: 1 1 auto;
}

.MuiGrid-item,
.MuiGrid-item-label {
  padding-left: 11px;
  padding-right: 11px;
}

.MuiGrid-item {
  margin-bottom: 10px;
  width: calc(100% + 10px);
}

.footerToolbar {
  align-items: center;
  width: 100%;
  padding-left: 20px;
}
.footerToolbar a, .footerToolbar a:visited {
  color: #0E4264;
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.2rem;
  height: 2rem;
  line-height: 2rem;
}
.footerToolbar a:hover, .footerToolbar a:visited:hover {
  text-decoration: none;
  color: #63798D;
}
.footerToolbar a:active, .footerToolbar a:focus, .footerToolbar a:visited:active, .footerToolbar a:visited:focus {
  background-color: #0E4264;
  color: #ffffff;
  border-radius: 15px;
}
.footerToolbar > .MuiGrid-item {
  background-color: #ffffff;
}
.footerToolbar .copyright {
  padding-left: 1rem;
  padding-right: 1rem;
}
.footerToolbar .footer-logo {
  width: 16.9rem;
  text-align: right;
  padding-right: calc(7px + 10px);
  background-color: unset;
}
.footerToolbar .footer-logo img {
  height: 52px;
}

.modal {
  position: absolute;
  top: calc(74px + 1px);
  left: calc(16.9rem + 10px);
  width: calc(100% - 16.9rem - 10px - 10px - 16.9rem);
  max-height: calc(100vh - (74px + 1px));
  padding: 32px 20px 0 20px;
  background-color: #ffffff;
  overflow: auto;
}

.archiveModal {
  height: inherit;
  padding: 0;
  left: calc((100vw - 550px) / 2);
  width: 550px;
  border-radius: 8px;
}
.archiveModal .modalDescription {
  padding: 28px;
  background-color: #FFEFA0;
  font-weight: 600;
}
.archiveModal .modalQuestion {
  padding: 28px;
  text-align: center;
}
.archiveModal .modalButtons {
  padding-bottom: 28px;
  text-align: center;
}
.archiveModal .modalButtons button {
  margin-left: 8px;
  margin-right: 8px;
}

@media all and (max-width: 1280px) {
  .modal {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: none;
  }
}
.leftDrawer {
  width: 16.9rem;
  flex-shrink: 0;
}
.leftDrawer .leftDrawerPaper {
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
  background-color: #0E4264;
  margin-left: 10px;
  border-right: none;
  margin-top: 74px;
}
.leftDrawer .leftDrawerPaper .leftDrawer-title {
  width: 16.9rem;
  background: #0E4264;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-top-left-radius: 8px;
}
.leftDrawer .leftDrawerPaper .left-drawer-list > div {
  background-color: #ffffff;
  border-right: 1px solid #ffffff;
}
.leftDrawer .leftDrawerPaper .left-drawer-list .leftListItemLink {
  border-color: #0E4264;
  background-color: #ffffff;
  border-right: 10px solid #ffffff;
  padding-left: 20px;
}
.leftDrawer .leftDrawerPaper .left-drawer-list .leftListItemLink:hover {
  background-color: #E3E7EB;
  border-right: 10px solid #63798D;
}
.leftDrawer .leftDrawerPaper .left-drawer-list .leftListItemLink:active, .leftDrawer .leftDrawerPaper .left-drawer-list .leftListItemLink:focus {
  color: #ffffff;
  background-color: #0E4264;
  border-right: 0;
}
.leftDrawer .leftDrawerPaper .left-drawer-list .leftListItemLink.active {
  border-right: 10px solid #FF3E60;
}

.rightDrawer {
  width: 16.9rem;
  flex-shrink: 0;
  position: fixed;
}
.rightDrawer .rightDrawerPaper {
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
  border-left: none;
  height: auto;
  background-color: #0E4264;
  margin-top: 74px;
}
.rightDrawer .rightDrawerPaper .rightDrawer-title {
  width: 16.9rem;
  background: #63798D;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-top-right-radius: 8px;
}
.rightDrawer .rightDrawerPaper .right-drawer-list > div {
  background-color: #E3E7EB;
  border-left: 1px solid #ffffff;
}
.rightDrawer .rightDrawerPaper .right-drawer-list .rightListItemLink {
  border-color: #ffffff;
  background-color: #E3E7EB;
  padding-left: 20px;
}
.rightDrawer .rightDrawerPaper .right-drawer-list .rightListItemLink:hover {
  background-color: #E3E7EB;
  border-left: 10px solid #63798D;
  padding-left: 10px;
}
.rightDrawer .rightDrawerPaper .right-drawer-list .rightListItemLink:active, .rightDrawer .rightDrawerPaper .right-drawer-list .rightListItemLink:focus {
  color: #ffffff;
  background-color: #0E4264;
  border-left: 0;
}

@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/WorkSans-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  src: url("fonts/WorkSans-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  src: url("fonts/WorkSans-Bold.otf") format("opentype");
}
.appBarToolbarMobile {
  display: none;
}

.mobileMenuOverlay {
  display: none;
}

.appBarToolbarMobileMenu {
  display: none;
  min-height: auto;
  height: 2.9rem;
  margin: 0;
  padding: 0;
}
.appBarToolbarMobileMenu.hidden {
  display: none;
}
.appBarToolbarMobileMenu .mobile-menu-selector {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  height: 46px;
  box-sizing: border-box;
  display: inline-block;
  line-height: 46px;
  border-bottom: 4px solid #0E4264;
  width: 50%;
}
.appBarToolbarMobileMenu .mobile-menu-selector button {
  padding: 7px 25px 3px 25px;
  min-width: 165px;
  border: none;
  width: 100%;
  color: #ffffff;
  background-color: #0E4264;
  text-decoration: none;
  text-transform: none;
  margin: 0;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
}
.appBarToolbarMobileMenu .mobile-menu-selector.active {
  border-bottom: 4px solid #FF3E60;
}

.MuiAppBar-colorPrimary .appBarToolbar .mobile-menu-icon {
  margin-left: 4px;
  display: none;
}

@media all and (max-width: 1280px) {
  .mobileMenuOverlay {
    width: 100%;
    height: 100%;
    background-color: #0E4264;
    opacity: 0.6;
    position: fixed;
    top: 0;
    left: 0;
    display: inherit;
  }
  .feedbackbackground {
    display: none;
  }
  .MuiAppBar-colorPrimary .appBarToolbar {
    height: 74px;
    padding-right: 9px;
  }
  .MuiAppBar-colorPrimary .appBarToolbar .mobile-menu-icon {
    display: inherit;
  }
  .MuiAppBar-colorPrimary .appBarToolbar .mobile-menu-icon > span > svg {
    height: 52px;
    width: 52px;
  }
  .MuiAppBar-colorPrimary .appBarToolbar .logo-container {
    display: none;
  }
  .MuiAppBar-colorPrimary .appBarToolbar .breadcrumb {
    display: none !important;
  }
  .MuiAppBar-colorPrimary .appBarToolbar .language-selector {
    display: none;
  }
  .MuiAppBar-colorPrimary .appBarToolbar .userMenu .userMenu-icon {
    right: 9px;
  }
  .appBarToolbarMobile {
    display: inherit;
  }
  .appBarToolbarMobileMenu {
    display: inherit;
  }
  .leftDrawer {
    position: fixed;
    flex-shrink: 0;
    height: initial;
    width: initial;
    display: none;
  }
  .leftDrawer.active {
    display: inherit;
    z-index: 100;
  }
  .leftDrawer .leftDrawerPaper {
    width: 16.9rem;
    background-color: #ffffff;
    margin-left: 0;
    border-right: none;
    box-shadow: none;
    margin-top: 0;
    top: calc(74px + 2.9rem);
  }
  .leftDrawer .leftDrawerPaper .drawer-title {
    display: none;
  }
  .leftDrawer .leftDrawerPaper .left-drawer-list {
    height: auto;
  }
  .leftDrawer .leftDrawerPaper .left-drawer-list > div {
    background-color: #ffffff;
    border-right: none;
  }
  .leftDrawer .leftDrawerPaper .left-drawer-list .leftListItemLink {
    border-color: #0E4264;
    background-color: #ffffff;
    border-right: 10px solid #ffffff;
    height: 50px;
  }
  .leftDrawer .leftDrawerPaper .left-drawer-list .leftListItemLink:hover {
    background-color: #E3E7EB;
    border-right: 10px solid #63798D;
  }
  .leftDrawer .leftDrawerPaper .left-drawer-list .leftListItemLink:active, .leftDrawer .leftDrawerPaper .left-drawer-list .leftListItemLink:focus {
    color: #ffffff;
    background-color: #0E4264;
    border-right: 0;
  }
  .leftDrawer .leftDrawerPaper .left-drawer-list .leftListItemLink.active {
    border-right: 10px solid #FF3E60;
  }
  .container {
    width: calc(100% - 1rem - 10px);
    padding: 0.5rem 0 6rem 0;
  }
  .rightDrawer {
    position: fixed;
    flex-shrink: 0;
    height: initial;
    width: initial;
    display: none;
  }
  .rightDrawer.active {
    display: inherit;
    z-index: 100;
  }
  .rightDrawer .rightDrawerPaper {
    width: 16.9rem;
    background-color: #0E4264;
    margin-right: 0;
    border-left: none;
    height: auto;
    margin-top: 0;
    top: calc(74px + 2.9rem);
  }
  .rightDrawer .rightDrawerPaper .drawer-title {
    display: none;
  }
  .rightDrawer .rightDrawerPaper .right-drawer-list > div {
    background-color: #E3E7EB;
    border-left: 1px solid #ffffff;
  }
  .rightDrawer .rightDrawerPaper .right-drawer-list .rightListItemLink:hover {
    background-color: #E3E7EB;
    border-left: 10px solid #63798D;
    padding-left: 10px;
  }
  .rightDrawer .rightDrawerPaper .right-drawer-list .rightListItemLink:active, .rightDrawer .rightDrawerPaper .right-drawer-list .rightListItemLink:focus {
    color: #ffffff;
    background-color: #0E4264;
    border-left: 0;
  }
  .rightDrawer .rightDrawerPaper .right-drawer-list .rightListItemLink.active {
    border-left: 10px solid #FF3E60;
    padding-left: 10px;
  }
  main {
    padding-top: calc(74px + 2.9rem);
    margin-right: 0;
  }
  footer .footerToolbar {
    margin-left: 0;
    width: 100%;
  }
  footer .footerToolbar .footer-logo {
    right: 0;
    position: fixed;
    bottom: 20px;
    padding-right: 10px;
  }
}

