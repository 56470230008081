@import "../variables";

$button-height: 45px;
$button-width: 43px;

$scrollbarWidth: 32px;

.search-list-wrapper {
  .search-list {
    font-family: Work Sans, sans-serif;
    font-style: normal;
    font-weight: bold;

    border: 1px solid $primary-gray;

    .search-list-title {
      background-color: $primary-gray;
      line-height: 1.25rem;
      padding: 12px 13px 7px 20px;
      align-items: center;
      text-transform: uppercase;
      color: $white;

    }

    .search-list-input {
      border-top: 1px solid $primary-gray;
      border-bottom: 1px solid $primary-gray;
      width: calc(100% - #{$button-width});
      height: $button-height;
      padding-left: 16px;

      > input {
        font-family: Open Sans, sans-serif;
        height: $button-height;
      }
    }

    .search-list-button {
      border: 1px solid $primary-gray;
      border-right: none;
      width: $button-width;
      height: $button-height;
      min-width: $button-width;

      padding: 0;
      border-radius: unset;
      float: right;

      .search-list-button-icon-image {
      }
    }

    .search-list-container {
      padding-top: 0;
      position: relative;
      overflow: auto;

      .search-list-item {
        padding: 9px 16px 5px 18px;
        border-top: 1px solid $disabledBlueGray;
        min-height: 44px;

        > div > span {
          font-family: Open Sans, sans-serif;
          font-style: normal;
          font-weight: normal;
        }

        &.selected {
          background-color: $primary-blue;
          color: $white;
        }
      }

      /* width */
      &::-webkit-scrollbar {
        width: $scrollbarWidth;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $white;
      }

      &::-webkit-scrollbar-track-piece {
        background: $white;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $disabledBlueGray;
        border: 9px solid $white;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $blueGray;
      }
    }

    &.disabled {
      .search-list-input {
        display: none;
      }

      .search-list-button {
        display: none;
      }

      .search-list-container {
        .search-list-item {
          border-color: $disabledBlueGray;
          cursor: default;

          &.selected {
            opacity: 1;
            background-color: $white;
            color: $black;
          }
        }

      }
    }

  }

  &.error {
    .search-list {
      border-color: $error-red;

      .search-list-input {
        border-color: $error-red;
      }

      .search-list-button {
        border-color: $error-red;
      }

      .search-list-container {
        .search-list-item {
          border-color: $error-red;
        }
      }
    }
  }
}

