@import "../variables";

.partition-header {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color:$black;
}