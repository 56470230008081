@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: normal;
  src: url("fonts/WorkSans-Regular.otf") format("opentype");
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 600;
  src: url("fonts/WorkSans-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 700;
  src: url("fonts/WorkSans-Bold.otf") format("opentype");
}