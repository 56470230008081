@import "../variables";

$inputWidth: 62px;
$rowHeight: 43px;
$borderWidth: 1px;

.input-list-container {
  box-sizing: border-box;


  .input-list {
    border: $borderWidth solid $blueGray;
    &[disabled] {
      border-color: $disabledBlueGray;
      .input-list-row {
        &.input-list-row-total {
          border-top: 1px solid $disabledBlueGray;
        }
      }
    }
    /* ROWS */
    .input-list-row {
      height: $rowHeight;

      > div {
        display: inline-block;
        height: $rowHeight;
      }

      .input-list-row-label {
        width: calc(100% - #{$inputWidth} - (#{$borderWidth} * 2));

        padding-left: 9px;
      }

      /* row value */
      &.input-list-row-0 .input-list-row-value {
        border-top: 0;
      }

      .input-list-row-value {
        width: $inputWidth;
        border: 0;
        border-top: 1px solid #C7D0D8;
        border-left: 1px solid #C7D0D8;

        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;

        input {
          line-height: 19px;
          font-family: Open Sans, sans-serif;
          background-color: $input-background;
          width: $inputWidth;
          height: calc(#{$rowHeight} - 1px);
          border: 0;
          padding-left: 10px;

          &[disabled] {
            border-color: $disabledBlueGray;
            color: $black;
          }

          &:focus {
            margin: 0;
            border: 3px solid $input-border-focus;
          }
        }
      }

      /* TOTAL */
      &.input-list-row-total {
        border-top: 1px solid $blueGray;

        .input-list-row-label {
          font-family: Open Sans, sans-serif;
          font-weight: 600;
          padding-top: 13px;
          padding-bottom: 13px;
        }

        .input-list-row-value {
          border: 0;
          padding-left: 10px;
          font-family: Open Sans, sans-serif;
        }
      }
    }
  }

  &.error {
    .input-list {
      border-color: $error-red;

      .input-list-row-value {
        input {
          border-color: $error-red;

          &:focus {
            border-color: $error-red;
          }
        }
      }

      .input-list-row-total {
        border-top-color: $error-red;
      }
    }
  }
}