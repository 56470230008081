@import "variables";
@import "font";

.appBarToolbarMobile {
  display: none;
}

.mobileMenuOverlay {
  display: none;
}

.appBarToolbarMobileMenu {
  display: none;
  //padding-bottom: 0;
  min-height: auto;
  height: $mobileAppBarHeight;
  //padding-left: 0;
  //margin-left: 13px;
  margin: 0;
  padding: 0;

  &.hidden {
    display: none;
  }

  .mobile-menu-selector {
    font-family: Work Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    height: 46px;
    box-sizing: border-box;
    display: inline-block;
    line-height: 46px;

    border-bottom: 4px solid $primary-blue;

    width: 50%;

    button {
      padding: 7px 25px 3px 25px;
      min-width: 165px;
      border:none;
      width: 100%;
      color: $white;
      background-color: $primary-blue;
      text-decoration: none;
      text-transform: none;
      margin: 0;
      font-family: Work Sans, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
    }

    &.active {
      border-bottom: 4px solid $red;
    }
  }
}

.MuiAppBar-colorPrimary {
  .appBarToolbar {
    .mobile-menu-icon {
      margin-left: 4px;
      display: none;
    }
  }
}

@media all and (max-width: $mobileBreakpointWidth) {
  .mobileMenuOverlay {
    width: 100%;
    height: 100%;
    background-color: $primary-blue;
    opacity: 0.6;
    position: fixed;
    top:0;
    left:0;
    display: inherit;
  }

  .feedbackbackground {
    display: none;
  }

  .MuiAppBar-colorPrimary {
    .appBarToolbar {
      height: $appBarHeight;
      padding-right: 9px;

      .mobile-menu-icon {
        display: inherit;

        > span > svg {
          height: 52px;
          width: 52px;
        }
      }

      .logo-container {
        display: none;
        .header-logo-normal {

        }
      }

      .breadcrumb {
        display: none !important;
      }

      .language-selector {
        display: none;
      }

      .userMenu .userMenu-icon {
        right: 9px;
      }
    }
  }
  .appBarToolbarMobile {
    display: inherit;
  }
  .appBarToolbarMobileMenu {
    display: inherit;
  }

  .leftDrawer {
    position: fixed;
    flex-shrink: 0;
    height: initial;
    width: initial;

    display: none;

    &.active {
      display: inherit;
      z-index: 100;
    }

    .leftDrawerPaper {
      width: $drawerWidth;
      background-color: $white;
      margin-left: 0;
      border-right: none;
      box-shadow: none;
      //height: auto;
      margin-top: 0;
      top: calc(#{$appBarHeight} + #{$mobileAppBarHeight});

      .drawer-title {
        display: none;
      }

      .left-drawer-list {
        height: auto;

        > div {
          background-color: $white;
          border-right: none;
        }

        .leftListItemLink {
          border-color: $primary-blue;
          background-color: $white;
          border-right: 10px solid $white;
          height: 50px;

          &:hover {
            background-color: $secondary-gray;
            // rgba(255, 255, 255,0.65);
            border-right: 10px solid $primary-gray;
          }

          &:active,
          &:focus {
            color: $white;
            background-color: $primary-blue;
            border-right: 0;
          }

          // subclasses
          &.active {
            border-right: 10px solid $red;
          }
        }
      }
    }
  }

  .container {
    width: calc(100% - 1rem - 10px);
    padding: 0.5rem 0 6rem 0;
  }
  .rightDrawer {
    position: fixed;
    flex-shrink: 0;

    height: initial;
    width: initial;

    display: none;

    &.active {
      display: inherit;
      z-index: 100;
    }

    .rightDrawerPaper {
      width: $drawerWidth;
      background-color: $primary-blue;
      margin-right: 0;
      border-left: none;
      height: auto;
      margin-top: 0;
      top: calc(#{$appBarHeight} + #{$mobileAppBarHeight});

      .drawer-title {
        display: none;
      }

      .right-drawer-list {
        > div {
          background-color: $secondary-gray;
          border-left: 1px solid $white;
        }

        .rightListItemLink {

          &:hover {
            background-color: $secondary-gray;
            // rgba(255, 255, 255,0.65);
            border-left: 10px solid $primary-gray;
            padding-left: 10px;
          }

          &:active,
          &:focus {
            color: $white;
            background-color: $primary-blue;
            border-left: 0;
          }

          // subclasses
          &.active {
            border-left: 10px solid $red;
            padding-left: 10px;
          }
        }

      }
    }
  }
  main {
    padding-top: calc(#{$appBarHeight} + #{$mobileAppBarHeight});
    margin-right: 0;
  }
  footer {
    .footerToolbar {
      margin-left: 0;
      width: 100%;

      .footer-logo {
        right: 0;
        position: fixed;
        bottom: 20px;
        padding-right: 10px;
      }
    }
  }

}
