@import "../variables";

$imageWidth: 84px;
$informationWidth: 135px;
$datesWidth: 100px;
$actionsWidth: 100px;
$leftColWidth: 30%;
$rightColWidth: 30%;

$rowHeight: 68px;
$padding: 10px;

.search_wrapper {
  display: flex;
  flex-direction: row;
}

.CompUserCards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .CompUserCards-row {
    width: 100%;
    height: 100px;
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid $blueGray;
    margin: 3px 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

    .cell-image {
      width: $imageWidth;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        vertical-align: middle;
        max-width: $imageWidth;
        max-height: calc(#{$rowHeight} - 6px);
        margin-left: 10px;
      }
    }

    .cell-content {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      margin: 0 10px;
      padding: $padding 0;

      cursor: pointer;

      .information {
        width: 40%;
        min-width: $leftColWidth;

        display: flex;
        justify-content: center;
        flex-direction: column;


        .title {
          font-weight: bold;
        }

        .desc {
        }
      }
      
      .roles {
        flex: 1 1;
        min-width: 60px;
        display: flex;
        align-items: center;
      }

      .organization {
        flex: 1;
        min-width: $informationWidth;

        display: flex;
        align-items: center;
      }

      .municipality {
        flex: 1;
        min-width: $informationWidth;

        display: flex;
        align-items: center;
      }
    }

    .cell-right {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-end;
      align-self: center;

      margin: 10px 10px 10px 0;

      .dates {
        width: $datesWidth;
        cursor: pointer;
      }

      .actions {
        width: $actionsWidth;
        .editButton {
          border: none;
          background-color: inherit;
          color: $link-blue;

          line-height: 12px;
          height: 24px;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          &:active,
          &:focus {
            color: $primary-blue;
          }
          .editButton-image {
            display: inline-block;
            vertical-align: middle;
            height: 24px;
          }

          .editButton-text {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .CompUserCards-header {
    height: inherit;

    border:none;
    margin: 0;
    box-shadow: none;

    font-family: Work Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    color: $primary-blue;

    .cell-image {
      cursor: default;
    }
    .cell-content {
      padding: 0;
      cursor: default;

      .information {}
      .organization {
        height: inherit;
        line-height: inherit;
      }
    }

    .cell-right {
      margin: 0 10px 0 0;
      .dates {
        //width: 100%;
        cursor: default;
      }
    }

  }

  .CompUserCards-footer {
    width: 100%;
    table {
      width: 100%;
      .MuiTablePagination-root {
        width: 100%;
        display: block;
      }
    }

    .MuiTablePagination-spacer {
      flex: 1 1 100%;
    }

    .MuiTablePagination-caption {
      flex-shrink: 0;
    }

    .MuiTablePagination-selectRoot {
      margin-left: 8px;
      margin-right: 32px;
    }
    .MuiTablePagination-input {
      color: inherit;
      font-size: inherit;
      flex-shrink: 0;
    }
    .MuiTablePagination-select {
      text-align: right;
      padding-left: 8px;
      padding-right: 24px;
      text-align-last: right;
    }
    .MuiSelect-select {
      cursor: pointer;
      min-width: 16px;
      user-select: none;
      border-radius: 0;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
    .MuiSelect-select.MuiSelect-select {
      padding-right: 24px;
    }
    .MuiInputBase-root {
      color: rgba(0, 0, 0, 0.87);
      cursor: text;
      display: inline-flex;
      position: relative;
      font-size: 1rem;
      box-sizing: border-box;
      align-items: center;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.1876em;
      letter-spacing: 0.00938em;
    }

    .MuiInputBase-input {
      font: inherit;
      color: currentColor;
      width: 100%;
      border: 0;
      height: 1.1876em;
      margin: 0;
      display: block;
      padding: 6px 0 7px;
      min-width: 0;
      background: none;
      box-sizing: content-box;
      animation-name: mui-auto-fill-cancel;
      letter-spacing: inherit;
      animation-duration: 10ms;
      -webkit-tap-highlight-color: transparent;
    }
    .MuiSelect-icon {
      top: calc(50% - 12px);
      color: rgba(0, 0, 0, 0.54);
      right: 0;
      position: absolute;
      pointer-events: none;
    }

    .MuiSvgIcon-root {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }

  }

}

@media all and (max-width: 480px) {
  .CompUserCards-row {
    flex-direction: column;
    padding: 10px;
    .cell-right {
      margin: 0 !important;
      margin-left: 10px !important;
      align-self: normal !important;
      justify-content: space-between !important;
      flex-direction: row !important;
    }
  }
}


@media all and (max-width: $mobileBreakpointWidth) {
  .search_wrapper {
    flex-direction: column;
  }
  .CompUserCards-container {

    .CompUserCards-row {
      height: inherit;
      .cell-content {
        flex-direction: column;
        .information {
          width:100%;
        }
      }
      .cell-right {
        width: initial;
        min-width: initial;
        align-self: center;
      }
    }

    .CompUserCards-header {
      display: none;
    }
  }
}