// COLORS
$lightBlueText: #69899C;
$black: #000;
$blueGray: #63798D;
$lightGray: #E3E7EB;
$disabledBlue: #E3E7EB;
$disabledBlueGray: #C7D0D8;
$disabledLightBlue: #B1DDF6;
$disabledLightBlueText: $lightBlueText;
$disabledYellow: #FCF1B9;
$notificationYellow: #FFEFA0;
$disabledYellowText: $lightBlueText;
$infoBalloon: #FFD9E3;
$link-blue: #255DD0;
$green: #137913;

$primary-blue: #0E4264;
$primary-gray: $blueGray;
$primary-yellow: #FFE561;
$secondary-gray: $lightGray;
$gray: #C7D0D8;
$secondary-blue: #7DC6F0;
$disabled-blue-link: $disabledYellowText;
$disabled-blue: $disabledLightBlue;
$disabled-yellow: $disabledYellow;
$disabled-gray: #9FB3C1;
$logo-pink: #FFC0D0;
$popover-pink: $infoBalloon;
$white: #ffffff;
$red: #FF3E60;
$error-red: #993366;

$main-background-color: $primary-blue;
$content-background-color: $white;

$focus-background-color: $primary-blue;
$focus-font-color: $white;

$input-text: $black;
$input-label: $primary-blue;
$input-background: $white;
$input-border: $primary-gray;
$input-border-focus: $primary-blue;

// LAYOUT
$alert-error-bg:#FFECEF;
$alert-warning-bg:#FFFADF;
$alert-info-bg:#E8F4FD;
$alert-success-bg:#FCFBFB;

$drawerWidth: 16.9rem;
$appBarHeight: 74px;
$appBarHeight-mobile: 4.4rem;
$mobileAppBarHeight: 2.9rem;
$drawerTitleBorderRadius: 8px;

$mobileBreakpointWidth :1280px;

$rightMargin: 10px;
$titleHeight: 2.8rem;
$footerLogoHeight:52px;

$inputMarginBottom: 24px;