@import "../variables";

.listLocationsComponent {
  min-height: 430px;
  &.loading {
    &::after {
      content: '';
      position: absolute;
      border: 5px solid #f3f3f3; /* Light grey */
      border-top: 5px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 2s linear infinite;
    }
    /* Safari */
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}

.location-container {
  >.MuiGrid-container{
    >.MuiGrid-item {
      position: relative;
      .tooltip-icon-container {
        position: absolute;
        top: -29px;
        float: right;
        right: 32px;
      }
    }
  }
}