@import "../variables";

$button-height: 45px;
$button-width: 43px;

$scrollbarWidth: 32px;

$baseItemPaddingLeft: 82px;
$itemPaddingLeft: 18px;
$treePadding: 20px;

.list-tree {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: bold;

  border: 1px solid $primary-gray;

  &.disabled {
    border-color: $disabledBlueGray;
    .MuiListItem-root.Mui-disabled {
      opacity: 1;
      .tree-opener-icon {
        opacity: 0.5;
      }
    }
  }

  .list-tree-title {
    background-color: $primary-gray;
    line-height: 1.25rem;
    padding: 12px 13px 7px 20px;
    align-items: center;
    text-transform: uppercase;
    color: $white;

  }

  .list-tree-container {
    padding-top: 0;
    position: relative;
    overflow: auto;

    .list-tree-item {
      padding: 9px 16px 5px $baseItemPaddingLeft;
      border-top: 1px solid $disabledBlueGray;

      > div > span {
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.9rem;
      }

      &.selected {
        background-color: $primary-blue;
        color: $white;
      }
      .tree-opener-icon {
        position: absolute;
        top:0;
        left:$itemPaddingLeft;
        padding: 4px 8px;;
      }
    }
    .list-tree-item-0 {

    }
    .list-tree-item-1 {
      padding-left: calc(#{$baseItemPaddingLeft} + (#{$treePadding} * 1));
      .tree-opener-icon {
        left:calc(#{$itemPaddingLeft} + (#{$treePadding} * 1));
      }
    }
    .list-tree-item-2 {
      padding-left: calc(#{$baseItemPaddingLeft} + (#{$treePadding} * 2));
      .tree-opener-icon {
        left:calc(#{$itemPaddingLeft} + (#{$treePadding} * 2));
      }
    }
    .list-tree-item-3 {
      padding-left: calc(#{$baseItemPaddingLeft} + (#{$treePadding} * 3));
      .tree-opener-icon {
        left:calc(#{$itemPaddingLeft} + (#{$treePadding} * 3));
      }
    }
    .list-tree-item-4 {
      padding-left: calc(#{$baseItemPaddingLeft} + (#{$treePadding} * 4));
      .tree-opener-icon {
        left:calc(#{$itemPaddingLeft} + (#{$treePadding} * 4));
      }
    }
    .list-tree-item-5 {
      padding-left: calc(#{$baseItemPaddingLeft} + (#{$treePadding} * 5));
      .tree-opener-icon {
        left:calc(#{$itemPaddingLeft} + (#{$treePadding} * 5));
      }
    }
    .list-tree-item-6 {
      padding-left: calc(#{$baseItemPaddingLeft} + (#{$treePadding} * 6));
      .tree-opener-icon {
        left:calc(#{$itemPaddingLeft} + (#{$treePadding} * 6));
      }
    }
    .list-tree-item-7 {
      padding-left: calc(#{$baseItemPaddingLeft} + (#{$treePadding} * 7));
      .tree-opener-icon {
        left:calc(#{$itemPaddingLeft} + (#{$treePadding} * 7));
      }
    }
    .list-tree-item-8 {
      padding-left: calc(#{$baseItemPaddingLeft} + (#{$treePadding} * 8));
      .tree-opener-icon {
        left:calc(#{$itemPaddingLeft} + (#{$treePadding} * 8));
      }
    }
    .list-tree-item-9 {
      padding-left: calc(#{$baseItemPaddingLeft} + (#{$treePadding} * 9));
      .tree-opener-icon {
        left:calc(#{$itemPaddingLeft} + (#{$treePadding} * 9));
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: $scrollbarWidth;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $white;
      border: solid 20px pink;
    }

    &::-webkit-scrollbar-track-piece {
      background: $white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $disabledBlueGray;
      border: 9px solid $white;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $blueGray;
    }
  }

  &.error {
    .error-label {
      padding: 0 13px 7px 20px;
    }
  }
}
