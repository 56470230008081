@import "../variables";

$noseBorderWidth: 12px;

.tooltip-icon-container {
  .popoverOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}
.tooltip-icon-popover {
  .MuiPopover-paper {
    background-color: $popover-pink;
    font-family: Open Sans, sans-serif;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
    overflow: initial;
    max-width: 300px;
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border: $noseBorderWidth solid transparent;
    }
    &.top {
      &::after {
        top: 100%;
        left: calc(50% - #{$noseBorderWidth});

        border-bottom: none;
        border-top-color: $popover-pink;
        filter: drop-shadow(0 2px 1px rgba(0, 0, 0, .2))
      }
    }
    &.left {
      &::after {
        left: 100%;
        top: calc(50% - #{$noseBorderWidth});

        border-right: none;
        border-left-color: $popover-pink;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .2))
      }
    }
    &.right {
      &::after {
        right: 100%;
        top: calc(50% - #{$noseBorderWidth});

        border-left: none;
        border-right-color: $popover-pink;
      }
    }
  }
}
