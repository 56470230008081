@import "../variables";

.comp-dropdown-container {
  width: 100%;
  margin-bottom: $inputMarginBottom;
  padding: 0;
  height: 44px;

  &.error {
    select {
      border-color: $error-red;

      &:focus {
        border-color: $error-red;
      }
    }
  }

  .MuiFormControl-root {
    &.error {
      .dropdown-select {
        border-color: $error-red;
      }
    }
  }
}

.dropdown-select {
  display: block;
  height: inherit;

  box-sizing: border-box;
  margin: 1px 0;
  border: 1px solid $input-border;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
  border-radius: 1px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  padding: 5px 12px;
  background-color: $input-background;
  width: 100%;
  max-width: 100%;
  cursor: pointer;

  //background-image: url('images/dropdown.svg');

  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;

  line-height: 1.3;

  ::-ms-expand {
    display: none;
  }
  &[disabled] {
    border-color: $disabledBlueGray;
    color: $black;
    cursor: default;
  }

  &:focus {
    box-shadow: none;
    outline: none;
    margin: 0;
    border-width: 2px;
    border-color: $input-border-focus;
  }

  option {
    background-color: $input-background;
    font-weight: normal;
    &:checked,
    &:hover{
      background-color: $focus-background-color;
      color: $focus-font-color;
    }
  }

  .functionCardPaginator {
    padding-right: 24px;
  }
    
  .MuiTablePagination-select {
    padding-right: 24px;
  }

  .MuiSelect-selectMenu {
    padding-right: 5px !important;
  }
}