@import "../variables";

$noseBorderWidth: 12px;

.balloon-notification-container {
  display: flex;
  flex-direction: column;
  .balloon-notification-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: $noseBorderWidth;

    .title {
      background: $primary-blue;
      border-radius: 32px;
      padding: 16px;
      overflow: initial;
      position: relative;
      max-height: 58px;

      font-family: Work Sans, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 58px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;

      color: $white;
      .icon-image {
        margin: -12px 0 -12px -12px;
        max-height: 54px;
        max-width: 65px;
      }
      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: $noseBorderWidth solid transparent;

        top: 100%;
        right: 25px;
        //left: calc(50% - #{$noseBorderWidth});

        border-bottom: none;
        border-top-color: $primary-blue;
      }
      &.notification {
        background: $logo-pink;
        color: $primary-blue;
        &::after {
          border-top-color: $logo-pink;
        }
      }
      &.reminder {
        background: $primary-yellow;
        color: $primary-blue;
        &::after {
          border-top-color: $primary-yellow;
        }
      }
    }

    .extra-elements {
      display: flex;
      flex-direction: column;

      .extra-element {

      }
    }
  }
  .content {

  }
}