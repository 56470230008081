@import "../variables";

.CompSystemLogTable-wrapper {
  .CompSystemLogTable-mainTitle {
    background-color: $blueGray;
    color: $white;
    text-transform: uppercase;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-style: normal;
    font-weight: bold;
  }

  @media all and (max-width: 500px) {
    .CompSystemLogTable-table {
      display: flex;

      .MuiTableHead-root {
        display: flex;
        place-items: flex-end;

        .MuiTableRow-root {
          display: flex;
          flex-direction: column;
        }
      }
      .MuiTableBody-root {
        display: flex;
        
        .MuiTableRow-root {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .CompSystemLogTable-container {
    .CompSystemLogTable-table {
      .MuiTableHead-root {
        .MuiTableRow-head {
          .MuiTableCell-head {
            background-color: $lightGray;
            color: $primary-blue;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          &.Mui-selected,
          &.Mui-selected:hover {
            background-color: $primary-blue;
            .MuiTableCell-body {
              color: $white;
            }
          }

          .MuiTableCell-body {
            &.selected {

            }
          }
        }
      }
      .MuiTableFooter-root {
        .MuiTableRow-footer {
          .CompSystemLogTable-paginator {
            flex-shrink: 0;
            margin-left: 8px;
          }
        }

      }
    }
  }
}
.systemLogFilterModal {
  display: flex;
  flex-direction: column;
  .title {
    margin-bottom:13px;
  }
  .selector {

  }
  .modalButtons {
    align-self: flex-end;
  }
}
