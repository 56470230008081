@import "../variables";
$minHeight:486px;

.listMunicipalitiesComponent {
  min-height: $minHeight;
  &.loading {
    &::after {
      content: '';
      position: absolute;
      border: 5px solid #f3f3f3; /* Light grey */
      border-top: 5px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 2s linear infinite;
    }
    /* Safari */
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }

  .search-list-container{
    max-height: 400px;
    .search-list-item{
      &.DRAFT {
        padding-left: 8px;
        border-left: 10px solid $primary-yellow;
      }
    }
  }
}
.listOrganizationsComponent {
  min-height: $minHeight;
  .list-tree-container {
    max-height: 445px;
  }
}

.municipality-container {
  >.MuiGrid-container{
    >.MuiGrid-item {
      position: relative;
      .tooltip-icon-container {
        position: absolute;
        top: -29px;
        float: right;
        right: 8px;
      }
    }
  }
}
